import loadable from '@loadable/component';
import {
  COMPONENT_INDEX, COMPONENT_OUTLINE, COMPONENT_NOTFOUND, COMPONENT_EMBEDDEDCONTENT, COMPONENT_MYDASHBOARD,
} from './constants';

const importMap = {};

/* Enable Async module loading and code splitting for client */
importMap[COMPONENT_OUTLINE] = loadable(() => import(/* webpackChunkName: "courseOutline" */'../pageTypes/courseOutline'));
importMap[COMPONENT_INDEX] = loadable(() => import(/* webpackChunkName: "index" */'../pageTypes/index'));
importMap[COMPONENT_NOTFOUND] = loadable(() => import(/* webpackChunkName: "notFound" */'../pageTypes/notFound'));
importMap[COMPONENT_EMBEDDEDCONTENT] = loadable(() => import(/* webpackChunkName: "embeddedContent" */'../pageTypes/embeddedContent'));
importMap[COMPONENT_MYDASHBOARD] = loadable(() => import(/* webpackChunkName: "userProfile" */'../pageTypes/myDashboard'));

export default importMap;
