import React, { useContext, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useHistory } from 'react-router-dom';
import window from 'global/window';
import { detect } from 'detect-browser';
import { valid, lt, coerce } from 'semver';
import {
  ENVIRONMENT,
  ERROR_TYPE_BROWSER,
  OXYGEN_RESPONSE_URL,
  PERF_ENV,
  PROD_ENV,
  STAGE_ENV,
} from './app/common/constants';
import routes from './app/common/routerConfig';
import AppNav from './app/components/appNav';
import handleUnauthRedirect from './app/common/handleUnauthRedirect';
import {
  fetchUserData, setUserDetails, clearLearningRecords,
} from './store/userSlice';
import EnvContext from './app/common/envContext';
import ErrorComponent from './app/components/error';
import { getErrorState, setErrorState } from './store/errorSlice';
import { setLoginRedirects } from './app/common/login';
import DHXOxygen from './lib/DHXOxygen';

/* Deprecated
const subscribeToLogOut = () => {
  // If pubsub wrapper has been created then subscribe
  if (window.adsk?.pubsubWrapper) {
    window.adsk.pubsubWrapper.subscribe(
      window.adsk.pubsubWrapper.adskConstants.SSO_SIGNED_IN,
      (key, isSignedIn) => {
        if (!isSignedIn) {
          const dispatch = useDispatch();
          dispatch(setUserDetails({}));
        }
      },
    );
  } else {
    // If pubsub wrapper has not been created yet wait 1 second and try again, repeatedly.
    setTimeout(subscribeToLogOut, 1000);
  }
};
*/

const waitForSignedOut = (env) => new Promise((resolve) => {
  let OxygenEnv;
  switch (env) {
    case PROD_ENV:
      OxygenEnv = 'production';
      break;
    case PERF_ENV:
      OxygenEnv = 'staging';
      break;
    case STAGE_ENV:
      OxygenEnv = 'staging';
      break;
    default:
      OxygenEnv = 'dev';
  }
  if (typeof DHXOxygen.setOptions === 'function') {
    DHXOxygen.setOptions({
      environment: OxygenEnv,
      viewmode: 'redirect',
      async onOpenIDResponse(params) {
        // code to handle the openid indirect responses
        switch (params.loginNeeded) {
          case 'false':
            // user is still logged in, check again
            DHXOxygen.checkImmediate(OXYGEN_RESPONSE_URL);
            break;
          case 'true':
          default:
            // user is logged out, put code here to display
            // the "Sign In" messaging on your UI
            resolve(params);
            break;
        }
      },
    });
  }
  DHXOxygen.checkImmediate(OXYGEN_RESPONSE_URL);
});

function RouterApp() {
  const dispatch = useDispatch();
  const envContext = useContext(EnvContext);
  const componentLoader = envContext.COMPONENT_LOADER;
  const errorState = useSelector(getErrorState);
  const history = useHistory();
  const env = envContext[ENVIRONMENT];

  useEffect(() => {
    // Catch sign out
    window.addEventListener('ME_MENU_SIGN_OUT', () => {
      // Wait for sign out to actually happen
      dispatch(setUserDetails({}));
      dispatch(clearLearningRecords());
      waitForSignedOut(env).then(() => {
        handleUnauthRedirect(history);
      });
    });

    // Catch sign in
    window.addEventListener('ME_MENU_SIGN_IN', () => {
      setLoginRedirects();
      // LoginClickHandler();
    });

    // Detect browser state
    const browser = detect();
    /*
    const unsupported = {
      os: ['BlackBerry OS', 'Windows 3.11'],
      browser: [{ browser: 'ie', lessThan: 'any' }, { browser: 'edge', lessThan: '84' }, { browser: 'chrome', lessThan: '83' }],
      combo: [{ os: 'Mac OS', browser: 'edge', lessThan: 'any' }, { os: 'Windows XP', browser: 'firefox', lessThan: '80.0' }],
    };
    */

    const unsupported = {
      browser: [{ browser: 'ie', lessThan: 'any' }],
    };

    // handle the case where we don't detect the browser
    if (browser) {
      const isBrowserUnsupported = unsupported.browser ? unsupported.browser.filter((b) => {
        if (b.browser === browser.name) {
          if (b.lessThan === 'any' || lt(browser.version, valid(coerce(b.lessThan)))) {
            return true;
          }
        }

        return false;
      }) : [];
      const isComboUnsupported = unsupported.combo ? unsupported.combo.filter((b) => {
        if (b.os === browser.os) {
          if (b.browser === browser.name) {
            if (b.lessThan === 'any' || lt(browser.version, valid(coerce(b.lessThan)))) {
              return true;
            }
          }
        }
        return false;
      }) : [];
      if (isBrowserUnsupported.length) {
        dispatch(setErrorState({ type: ERROR_TYPE_BROWSER, metaData: { browser, type: 'browser' } }));
      } else if (isComboUnsupported.length) {
        dispatch(setErrorState({ type: ERROR_TYPE_BROWSER, metaData: { browser, type: 'combo' } }));
      }
    }

    // Get User State
    dispatch(
      fetchUserData(envContext[ENVIRONMENT]),
    );
  }, []);

  return (

    <>
      <GlobalStyles />

      <RootElement className="wd-ts-base style-override">
        <AppNav />
        <div id="contentRoot" className="page-content">
          {!errorState.hasError
            ? (
              <Switch>
                {routes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    strict={route.strict || false}
                    exact={route.exact || false}
                    component={componentLoader[route.componentKey]}
                    status={route.status || 200}
                  />
                ))}
              </Switch>
            )
            : <ErrorComponent /> }
        </div>
      </RootElement>
    </>

  );
}

const GlobalStyles = createGlobalStyle`
  .wd-ts-base.style-override {
     .wd-cta {
       text-transform: none;
       min-width: auto;
       background-color: #006eaf;      
     }
     .wd-cta-outline {
       background-color: #fff;
     }
    a:not(.wd-cta) {
      background: transparent;
      border-bottom: 0px;
    }
    h1 {
      font-family: 'Artifakt';
      font-weight: normal;
    }
    h2 {
      font-weight: normal;
    }
    p, ul, h1, h2, h3, li {
      color: #3c3c3c;
    }
  }
  .incompatibleOverlayMask {
    background: rgba(255,255,255,0.5);
    z-index:2000;
    position:fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
`;

const RootElement = styled.div`
    min-height: 40em;
    padding-top: 102px;
    
    `;

export default RouterApp;
