import { createSlice } from '@reduxjs/toolkit';
import { DEV_ENV, PROD_ENV } from '../app/common/constants';

export const envSlice = createSlice({
  name: 'envData',
  initialState: {
    ENVIRONMENT: DEV_ENV,
    LMS_HEADERS: { },
  },
});

export const getEnvProp = (state, prop) => state.envData[prop];
export const getLMSHeaders = (state) => {
  if (state.envData.ENVIRONMENT !== PROD_ENV) {
    return state.envData.LMS_HEADERS;
  }

  return {};
};

export default envSlice.reducer;
