import sha256 from 'crypto-js/sha256';
import window from 'global';
import logger from './logger';
import {
  BLOCK_TYPE_UNIT,
  BLOCK_TYPE_COURSE,
  LRS_VERB_COMPLETED,
  LRS_VERB_STARTED,
  OBJECT_TYPE_UNIT,
  BLOCK_TYPE_PROBLEM,
  OBJECT_TYPE_PROBLEM,
  LMS_FORMAT_PRETEST,
  ROUTER_BASENAME,
  LOCAL_STORAGE_LRS_KEY, ENVIRONMENT_CONSTANTS, ENVIRONMENT, PRELOADED_STATE_KEY,
} from './constants';

/* Future code for browse
export const GenerateCatalogUriFromState = ({ filterSet, currentPage, match }) => {
  const filterKeys = [];
  const filterValues = [];

  filterSet.forEach((filterGroup) => {
    const selectedIds = filterGroup.values.filter((filter) => filter.selected).map((filter) => filter.id);
    if (selectedIds.length) {
      filterKeys.push(filterGroup.id);
      filterValues.push(selectedIds.join(','));
    }
  });

  if (currentPage) {
    filterKeys.push('Page');
    filterValues.push(currentPage);
  }

  return `/learn/search/${match.params.term}/${filterKeys.join('|')}/${filterValues.join('|')}`;
};

export const CreateFutureFilterState = ({
  filterState, filterGroupId, filterId, filterValue,
}) => {
  const nextState = filterState.map((filterGroup) => {
    if (filterGroup.id === filterGroupId) {
      const updatedFilterGroup = filterGroup.values.map((filter) => {
        if (filter.id === filterId) {
          return { ...filter, selected: filterValue };
        }
        return filter;
      });

      return { ...filterGroup, values: updatedFilterGroup };
    }
    return filterGroup;
  });

  return nextState;
};
*/
export const oneWayHash = (data) => sha256(`sdgjk34${data}`);

export const getEnvironmentConstant = ({ key, env }) => {
  if (ENVIRONMENT_CONSTANTS[env] && ENVIRONMENT_CONSTANTS[env][key]) {
    const defaultValue = ENVIRONMENT_CONSTANTS[env][key];
    return process.env[key] || window[PRELOADED_STATE_KEY]?.envData[key] || defaultValue;
  }
  logger.error(`${key} not found in environment constants config`);
  return null;
};

export const stripTrailingSlash = (str) => (str.endsWith('/') ? str.slice(0, -1) : str);

export const isLearningRecordForCourse = (recId) => recId.includes('course-v1');

// Convert course ID to URI for overview page
export const generateCourseURI = (courseId) => decodeURIComponent(courseId
  .replace(new RegExp('^block-v1'), 'course-v1')
  .replace(new RegExp('\\+type@course\\+block@course$'), ''));

// Convert course ID to URI for overview page
export const generateCourseBlockURI = (courseId, blockId) => `${generateCourseURI(courseId)}/${decodeURIComponent(blockId)}`;

// Convert course student_view_url to valid URL for LRS
export const convertCourseXBlockUrl = (block) => {
  if (!block.student_view_url.includes('+type')) {
    return block.student_view_url;
  }

  return block.student_view_url
    .replace(`xblock/${block.id}`, `courses/${block.id.split('+type')[0].replace('block-v1:', 'course-v1:')}/`);
};

// Generate URN for LRS Producer API
export const generateURN = ({ uid, verb, block }) => {
  // Default objectType to block type from LMS
  let objectType = block.type;
  let url = block.student_view_url;

  // Remap object types from LMS into Object Type for API
  // Object Types: https://airtable.com/tblLHZc9AvtUCHQlQ/viwj36c4Uo2j4qcMy?blocks=show
  switch (block.type) {
    case BLOCK_TYPE_UNIT:
      objectType = OBJECT_TYPE_UNIT;
      break;
    case BLOCK_TYPE_PROBLEM:
      objectType = OBJECT_TYPE_PROBLEM;
      break;
    case BLOCK_TYPE_COURSE:
      url = convertCourseXBlockUrl(block);
      break;
    default:
      break;
  }

  return `${uid}:${verb}:urn:${objectType}:${url}`;
};

export const generateLRSContext = ({
  blocks, block,
}) => {
  // Handle lack of blocks data
  if (!Object.keys(blocks).length) {
    return {};
  }

  let lessonId;
  let chapterId;
  let courseId;

  switch (block.type) {
    case BLOCK_TYPE_UNIT:
      lessonId = Object.keys(blocks).filter((id) => blocks[id].children && blocks[id].children.includes(block.id))[0];
      chapterId = Object.keys(blocks).filter((chid) => blocks[chid].children && blocks[chid].children.includes(lessonId))[0];
      courseId = Object.keys(blocks).filter((coid) => blocks[coid].children && blocks[coid].children.includes(chapterId))[0];
      return { parent: blocks[lessonId].student_view_url, grouping: blocks[courseId].student_view_url };
    default:
      return {};
  }
};

// Flatten array of arrays
export const flatDeep = (arr, d = 1) => (d > 0
  ? arr.reduce((acc, val) => acc.concat(Array.isArray(val)
    ? flatDeep(val, d - 1) : val), [])
  : arr.slice());

// Order course pages in the entire course tree
export const orderCourseIds = ({ blocks, root }) => {
  const arraysOfBlocks = blocks[root]?.children?.map(
    (childId) => blocks[childId]?.children?.map(
      (grandChildId) => blocks[grandChildId].children || [],
    ) || [],
  ) || [];

  const flatBlocks = flatDeep(arraysOfBlocks, 3);

  return flatBlocks;
};

// Order subsections of course tree
export const orderSectionIds = ({ blocks, root }) => {
  const arraysOfBlocks = blocks[root]?.children?.map(
    (childId) => blocks[childId].children || [],
  ) || [];

  const flatBlocks = flatDeep(arraysOfBlocks, 3);

  return flatBlocks;
};

// Validate if a given block has a completed LRS record
export const isBlockComplete = ({ learningRecords, blocks, id }) => {
  if (blocks && blocks[id]) {
    const recordId = blocks[id].student_view_url;
    if (learningRecords && learningRecords[recordId] && learningRecords[recordId].statements) { // If learning record for this block exists
      const completedStatements = learningRecords[recordId].statements.filter((statement) => statement.verb === LRS_VERB_COMPLETED);
      if (completedStatements.length) { // Found a completed statement for this child.
        return true;
      }
    }
  }
  return false;
};

// Validate if course has a started LRS record
export const isCourseStarted = ({ learningRecords, blocks, root }) => {
  if (blocks && blocks[root]) {
    // student_view_url is not a valid identifier for courses.  Convert it.
    const courseId = convertCourseXBlockUrl(blocks[root]);
    if (learningRecords && learningRecords[courseId] && learningRecords[courseId].statements) { // If learning record for this block exists
      const startedStatements = learningRecords[courseId].statements.filter((statement) => statement.verb === LRS_VERB_STARTED);
      if (startedStatements.length) { // Found a completed statement for this child.
        return true;
      }
    }
  }
  return false;
};

// Has every unit been completed - This != passed which is a subset of completed
export const isCourseComplete = ({ blocks, learningRecords, orderedIds }) => orderedIds.every((itemId) => {
  const lrsId = blocks[itemId].student_view_url;
  if (!learningRecords[lrsId] || !learningRecords[lrsId].statements) {
    return false;
  }
  return learningRecords[lrsId].statements.some((s) => s.verb === LRS_VERB_COMPLETED);
});

export const getIncompleteProblemsList = ({ blocks, learningRecords, itemId }) => Object.keys(blocks).filter((blockId) => {
  // Shortcut for just completed quiz that is not updated in record store yet
  if (blockId === itemId) {
    return false;
  }
  const block = blocks[blockId];
  // If block is a graded problem check for LRS Record
  if (block.type === BLOCK_TYPE_PROBLEM && block.graded) {
    // Shortcut if grandparent is "Pretest"
    // Is Grandparent (Chapter) of Pretest format
    const parentId = Object.keys(blocks).filter((id) => blocks[id].children && blocks[id].children.includes(block.id))[0];
    const grandParentId = Object.keys(blocks).filter((gid) => blocks[gid].children && blocks[gid].children.includes(parentId))[0];
    if (blocks[grandParentId] && blocks[grandParentId].format === LMS_FORMAT_PRETEST) {
      return false;
    }

    // If LRS record not found or does not contain completed statement return true - "incomplete graded problem"
    if (!learningRecords[block.student_view_url]
        || !learningRecords[block.student_view_url].statements
        || learningRecords[block.student_view_url].statements.filter((statement) => statement.verb === LRS_VERB_COMPLETED).length < 1) {
      return true;
    }
  }
  return false;
});

export const areCourseGradedComplete = ({
  learningRecords, blocks, root, itemId,
}) => {
  if (blocks && root) {
    const courseBlock = blocks[root];
    // Check if course complete statement already exists
    if (learningRecords && courseBlock) {
      const courseRecordId = convertCourseXBlockUrl(courseBlock);
      if (learningRecords[courseRecordId]
        && learningRecords[courseRecordId].statements
        && learningRecords[courseRecordId].statements.filter((statement) => statement.verb === LRS_VERB_COMPLETED).length > 0) {
        // Found course complete record
        return true;
      }
      // Completed record not found so let's see if course is complete
      if (learningRecords && blocks) {
        const incompleteGradedProblems = getIncompleteProblemsList({ blocks, learningRecords, itemId });
        // If no incomplete graded problems then course is complete
        if (!incompleteGradedProblems.length) {
          return true;
        }
      }
    }
  }

  return false;
};
export const shouldFireGradedComplete = ({
  learningRecords, blocks, root, itemId,
}) => {
  if (blocks && root) {
    const courseBlock = blocks[root];
    // Check if course complete statement already exists
    if (learningRecords && courseBlock) {
      const courseRecordId = convertCourseXBlockUrl(courseBlock);
      if (!learningRecords[courseRecordId]
        || !learningRecords[courseRecordId].statements
        || learningRecords[courseRecordId].statements.filter((statement) => statement.verb === LRS_VERB_COMPLETED).length < 1) {
        // Completed record not found so let's see if course is complete
        if (learningRecords && blocks) {
          const incompleteGradedProblems = getIncompleteProblemsList({ blocks, learningRecords, itemId });

          // If no incomplete graded problems then course is complete
          if (!incompleteGradedProblems.length) {
            return true;
          }
        }
      }
    }
  }

  return false;
};

// Split large array into array of smaller arrays
export const chunkArray = (array, size) => {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    const chunk = array.slice(i, i + size);
    result.push(chunk);
  }
  return result;
};

// Generate list of all object ids in a course that could have LRS records: course, lesson, problem
export const generateRecordFetchingList = ({ blocks, root }) => {
  // Start list with lessons
  const lessonList = Object.keys(blocks).filter((id) => blocks[id].type === BLOCK_TYPE_UNIT).map((id) => blocks[id].student_view_url);
  // Add problems to list
  const problemList = Object.keys(blocks).filter((id) => blocks[id].type === BLOCK_TYPE_PROBLEM).map((id) => blocks[id].student_view_url);
  const recordList = lessonList.concat(problemList);
  // Add course to list
  recordList.push(convertCourseXBlockUrl(blocks[root]));
  return recordList;
};

export const getNextIncompleteUnitUrl = ({
  blocks, orderedIds, learningRecords, rootKey,
}) => {
  // Find all items that have not been completed
  // console.log(orderedIds);
  const incompleteIds = orderedIds.filter((itemId) => {
    const block = blocks[itemId];
    // console.log(itemId, block);
    const record = learningRecords[blocks[itemId].student_view_url];
    if (block
      && record
      && learningRecords[blocks[itemId].student_view_url].statements
      && learningRecords[blocks[itemId].student_view_url].statements.filter(
        (statement) => statement.verb === LRS_VERB_COMPLETED,
      ).length !== 0) {
      return false;
    }

    return true;
  });

  if (incompleteIds.length) {
    // Redirect to first incomplete item
    return `/course/${generateCourseBlockURI(blocks[rootKey].id, incompleteIds[0])}`;
  }

  return null;
};

export const getLocaleString = () => {
  if (Intl && typeof Intl.NumberFormat === 'function') {
    return (new Intl.NumberFormat()).resolvedOptions().locale;
  }

  // Default to en-us
  return 'en-US';
};

export const generateCanonicalURL = (location, requestHost) => `https://${requestHost}${ROUTER_BASENAME}${decodeURIComponent(location.pathname)}`;
