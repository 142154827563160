import {
  LIB_EVENT,
  LIB_EVENT_VALUE,
  LIB_EVENT_PLUGIN_RESIZE,
  LIB_EVENT_URL,
  LIB_EVENT_ERROR,
  LIB_EVENT_ERROR_CODE,
  LIB_EVENT_ERROR_MESSAGE, LIB_EVENT_ERROR_TIMESTAMP,
} from './constants';
import logger from './logger';

const callBackTypes = {};

export default (event) => {
  if (event && event.data && event.data[LIB_EVENT]) {
    if (event.data[LIB_EVENT] === LIB_EVENT_ERROR) {
      logger.error(`Error Message Received: ${event.data[LIB_EVENT_ERROR_CODE]} - ${event.data[LIB_EVENT_ERROR_MESSAGE]} -  ${event.data[LIB_EVENT_ERROR_TIMESTAMP]}`);
      return;
    }
    if (callBackTypes[event.data[LIB_EVENT]]) {
      if (Object.hasOwnProperty.call(event.data, LIB_EVENT_VALUE)) {
        callBackTypes[event.data[LIB_EVENT]](event.data[LIB_EVENT_VALUE]);
        logger.debug(`Completed ${event.data[LIB_EVENT]} callback with value ${event.data[LIB_EVENT_VALUE]}`);
      } else if (Object.hasOwnProperty.call(event.data, LIB_EVENT_URL)) {
        callBackTypes[event.data[LIB_EVENT]](event.data[LIB_EVENT_URL]);
        logger.debug(`Completed ${event.data[LIB_EVENT]} callback with url ${event.data[LIB_EVENT_URL]}`);
      } else {
        logger.debug('Received child message with unhandled type: ', event.data);
      }
    } else {
      logger.debug('Received child message with unhandled type: ', event.data);
    }
  } else if (event?.data?.type === LIB_EVENT_PLUGIN_RESIZE) {
    callBackTypes[LIB_EVENT_PLUGIN_RESIZE](event?.data?.payload?.height);
    logger.debug(`Completed ${LIB_EVENT_PLUGIN_RESIZE} callback with value ${event?.data?.payload?.height}`);
  } else {
    logger.debug('Received unknown child message: ', event?.data);
  }
};

export const setCallbackForType = (type, callback) => {
  callBackTypes[type] = callback;
};

export const clearCallbackForType = (type) => {
  callBackTypes[type] = () => ({});
};
