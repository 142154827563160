// This file is shared for SSR and CSR.  The functions define the async calls for getting data
import axios from 'axios';
import axiosRetry from 'axios-retry';
import {
  RETRY_COUNT,
  EDX_HOST,
  learnOutlineSliceInitialState as outlineInitialState,
  learnOutlineSliceName as outlineName,
} from '../app/common/constants';
import logger from '../app/common/logger';

import { EdXApiTimer } from '../app/common/timers';
import XHRError from '../app/common/xhrError';
import { getEnvironmentConstant } from '../app/common/utilities';

axiosRetry(axios, { retries: RETRY_COUNT });

// Returns a promise that resolves user data
export const getOutlineState = ({
  course, env, useMocks = false, lmsHeaders = {},
}) => {
  const encodedCourse = encodeURIComponent(decodeURIComponent(course));
  // Shortcut return mocks
  if (useMocks) {
    return new Promise((resolve) => setTimeout(() => {
      import(/* webpackChunkName: "loadTestStateMock" */ '../mocks/loadTestStateMock.json').then((mockData) => {
        resolve(mockData.outlineData.outline);
      });
    }, 1000));
  }
  logger.debug('Env: ', env);
  const host = getEnvironmentConstant({ env, key: EDX_HOST });
  logger.debug('Determined Host: ', host);
  const courseFetchURL = `${host}/api/courses/v1/courses/${encodedCourse}`;
  const blockFetchURL = `${host}/api/courses/v1/blocks/?course_id=${encodedCourse}&depth=all&requested_fields=format,graded,due,children,other_course_settings&all_blocks=true`;

  logger.debug('Course Fetch URL: ', courseFetchURL);

  logger.debug('Block Fetch URL: ', blockFetchURL);

  const coursePromiseStart = new Date().getTime();

  let coursePromiseTime;
  const coursePromise = new Promise((resolve, reject) => {
    const courseTimer = new EdXApiTimer();
    courseTimer.set(courseFetchURL);
    axios
      .get(courseFetchURL, lmsHeaders)
      .catch((err) => {
        courseTimer.clear();
        const xhrErr = new XHRError(err?.response?.status || 500, err.message);
        reject(xhrErr);
      })
      .then((payload) => {
        courseTimer.clear();
        coursePromiseTime = new Date().getTime() - coursePromiseStart;
        resolve({ ...payload?.data });
      })

      .catch((err) => {
        courseTimer.clear();
        reject(err);
      });
  });

  const blockPromiseStart = new Date().getTime();
  let blockPromiseTime = 0;
  const blockPromise = new Promise((resolve, reject) => {
    const blockTimer = new EdXApiTimer();
    blockTimer.set(blockFetchURL);
    axios
      .get(blockFetchURL, lmsHeaders)
      .catch((err) => {
        blockTimer.clear();
        const xhrErr = new XHRError(err?.response?.status || 500, err.message);
        reject(xhrErr);
      })
      .then((payload) => {
        blockPromiseTime = new Date().getTime() - blockPromiseStart;
        blockTimer.clear();
        resolve({ ...payload?.data });
      })
      .catch((err) => {
        blockTimer.clear();
        reject(err);
      });
  });

  return Promise.all([coursePromise, blockPromise])
    // .catch((err) => ([err]))
    .then((values) => {
      logger.debug(`Timing:\nCourse Data call: ${coursePromiseTime}ms\nBlock Data call: ${blockPromiseTime}ms`);
      if (values.length === 2) {
        return { ...values[0], ...values[1] };
      }
      return { ...values[0] };
    });
};
/* Comment out block used for future browse state
export const getCatalogState = async ({ match, env }) => {
  const { term: course } = match.params;
  const host = getEnvironmentConstant({ env, key: EDX_HOST });
  logger.info('getCatalogState course name: ', course);
  const fetchURL = `${host}/api/courses/v1/blocks/?course_id=${course}&depth=all&requested_fields=graded,due,children&all_blocks=true`;
  const response = await axios
    .get(fetchURL)
    .then((payload) => ({ ...payload.data }));

  return response;
};

export const parseFilterSetFromPath = (state, { url }) => {
  let { filterSet, currentPage } = { ...state };
  const [, , , ,
    filterKeys, filterValues,
  ] = url.split('/');
  if (filterKeys && filterValues) {
    const filterKeyList = filterKeys.split('|');
    const filterValuesList = filterValues.split('|');
    filterSet = state.filterSet.map((filterGroup) => {
      const updatedFilterGroup = { ...filterGroup };
      const filterGroupPosition = filterKeyList.indexOf(filterGroup.id);
      if (filterGroupPosition >= 0) {
        const filterGroupValues = filterValuesList[filterGroupPosition].split(',');
        updatedFilterGroup.values = updatedFilterGroup.values.map((filterValue) => {
          if (filterGroupValues.includes(filterValue.id)) {
            return { ...filterValue, selected: true };
          }
          return { ...filterValue, selected: false };
        });
      } else {
        // Turn off all unspecified filters because they may have been deselected.
        updatedFilterGroup.values = updatedFilterGroup.values.map((filterValue) => ({
          ...filterValue,
          selected: false,
        }));
      }

      return updatedFilterGroup;
    });

    // Handle Page
    const pagePosition = filterKeyList.indexOf('Page');
    if (pagePosition >= 0) {
      currentPage = parseInt(filterValuesList[pagePosition], 10) || 1;
    }
  }

  if (JSON.stringify(state.filterSet) === JSON.stringify(filterSet)) {
    return { currentPage };
  }

  return { filterSet, currentPage };
};
*/
export const initialState = {};

initialState[outlineName] = outlineInitialState;
