import { configureStore } from '@reduxjs/toolkit';
// import catalogData from './learnCatalogSlice';
import outlineData from './learnOutlineSlice';
import envData from './envSlice';
import userData from './userSlice';
import errorData from './errorSlice';
import embeddedContent from './embeddedContentSlice';

const reducer = {
  // catalogData,
  outlineData,
  userData,
  envData,
  errorData,
  embeddedContent,
};

export default configureStore({
  reducer,
});

export const generateStore = (preloadedState) => configureStore({
  reducer,
  preloadedState,
});
