import window from 'global';
import axios from 'axios';
import axiosRetry from 'axios-retry';

import {
  LOG_LEVEL_DEBUG,
  LOG_LEVEL_INFO,
  LOG_LEVEL_WARN,
  LOG_LEVEL_ERROR,
  LOG_LEVEL,
  ENVIRONMENT_CONSTANTS,
  ENVIRONMENT,
  DEV_ENV,
  PRELOADED_STATE_KEY,
  LOGGER_URL,
  RETRY_COUNT,
} from './constants';

axiosRetry(axios, { retries: RETRY_COUNT });

const env = process.env[ENVIRONMENT] || window[PRELOADED_STATE_KEY]?.envData[ENVIRONMENT] || DEV_ENV;
const applicationLogLevel = process.env[LOG_LEVEL] || window[PRELOADED_STATE_KEY]?.envData[LOG_LEVEL] || ENVIRONMENT_CONSTANTS[env][LOG_LEVEL];

// Values used to determine if the request meets the current logging level requirement
const levelValues = {};
levelValues[LOG_LEVEL_DEBUG] = 0;
levelValues[LOG_LEVEL_INFO] = 1;
levelValues[LOG_LEVEL_WARN] = 2;
levelValues[LOG_LEVEL_ERROR] = 3;

/**
 * Process an argument passed to the logger and return message and stack trace if available.
 * Designed to handle the passing of strings, objects, and errors to the logger.
 *
 * @param arg
 * @returns {{message: *, stack: *}}
 */
function processArgument(arg) {
  let message;
  let stack;
  let preformatted;
  let filename;

  if (typeof arg === 'object') {
    if (arg.message) {
      if (arg.stack) {
        stack = arg.stack.replace(/\n/g, ';');
      }
      if (arg.filename) {
        filename = arg.filename;
      }
      message = arg.message;
    } else if (arg.preformatted) {
      preformatted = arg.preformatted;
    } else {
      message = JSON.stringify(arg);
    }
  } else {
    message = arg;
  }

  return {
    message, stack, preformatted, filename,
  };
}

const formatMessage = (method, args) => {
  let stackTrace;
  let preformattedMsg;
  let file;
  const errorMessage = [];

  // Get message and stack trace
  args.forEach((arg) => {
    const {
      message, stack, preformatted, filename,
    } = processArgument(arg);
    if (message) {
      errorMessage.push(message);
    }
    if (stack) {
      stackTrace = stack;
    }
    if (preformatted) {
      preformattedMsg = preformatted;
    }
    if (filename) {
      file = filename;
    }
  });

  if (preformattedMsg) {
    return preformattedMsg;
  }

  if (stackTrace) {
    errorMessage.push(stackTrace);
  }

  const logObj = {
    payload: errorMessage.join(' - '),
    timestamp: Date.now(),
    level: method,
    transactionId: 'Missing Transaction-ID',
    service: `credentialing SPA ${window.location ? 'UI' : 'Server'}`,
    environment: env,
    programLocation: file || '',
  };

  // Add Browser Data
  if (window.location) {
    logObj.OS = window.navigator?.platform;
    logObj.Language = window.navigator?.language;
    logObj.Browser = window.navigator?.userAgent;
  }

  return JSON.stringify(logObj);
};

/**
 * This method actually logs.
 *
 * @param method - debug, info, warn or error
 * @param args - strings/objects combined to generate the message and stack trace
 */
const log = (method, ...args) => {
  if (!Object.hasOwnProperty.call(levelValues, method)) {
    throw new Error('First parameter for the logger method must be debug/info/warn/error');
  }

  if (levelValues[method] >= levelValues[applicationLogLevel]) {
    console[method](formatMessage(method, args));

    const logCollectorUrl = window[PRELOADED_STATE_KEY]?.envData[LOGGER_URL] || false;
    if (window.location && logCollectorUrl) {
      axios.post(logCollectorUrl, { msg: formatMessage(method, args) });
    }
  }
};

const error = (...args) => log('error', ...args);
const warn = (...args) => log('warn', ...args);
const debug = (...args) => log('debug', ...args);
const info = (...args) => log('info', ...args);

export default {
  log,
  debug,
  info,
  warn,
  error,
};
