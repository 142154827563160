import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { window, document } from 'global';
import { ERROR_TYPE_404, ERROR_TYPE_BROWSER } from '../common/constants';
import { getErrorState, clearErrorState } from '../../store/errorSlice';

let clearState;

const goBack = (e) => {
  e.preventDefault();
  clearState();
  window.history.back();
};

const NotFound = (
  <div className="responsive-grid section root404 rootError">
    <div className="row wd-ph-20">
      <div className="large-8 grid-columns">
        <div className="responsive-column-0 responsive-column">
          <div className="column-content parsys">
            <div className="text section">
              <p className="wd-color-dark wd-bbw-1 wd-bc-smoke wd-pb-12 wp-upper-line"><b>404 error</b></p>
            </div>
            <div className="heading section">
              <h2 className="wd-normal wd-mb-10 wd-mt-12">
                We can&apos;t seem to find that page.
              </h2>
            </div>
            <div className="text section">
              <p className="wd-mt-0 wd-mb-32 wd-font-14" style={{ lineHeight: '20px !important' }}>
                There&apos;s a lot to explore
                here, and we want to help you find it. Please try your search again or use the links below to see our most
                popular products and services.
              </p>
            </div>
            <div className="text section">
              <p className="wd-font-14 wd-bs-dashed wd-bbw-1 wd-bc-smoke wd-pb-12">
                <a
                  href="/"
                  data-wat-link="true"
                  data-wat-loc="content"
                  data-wat-val="cta: autodesk home"
                  className="wd-inline-icon"
                >
                  <svg className="wd-icon icon-svg-cta-go-arrow-circle-line pc-brand ">
                    <use xlinkHref="#icon-svg-cta-go-arrow-circle-line" />
                  </svg>
                  <span className="wd-ml-8">Go to Autodesk home</span>
                </a>
              </p>
            </div>
            <div className="text section">
              <p className="wd-font-14 wd-bs-dashed wd-bbw-1 wd-bc-smoke wd-pb-12">
                <a
                  href="/products"
                  data-wat-link="true"
                  data-wat-loc="content"
                  data-wat-val="cta: shop autodesk products"
                  className="wd-inline-icon"
                >
                  <svg className="wd-icon icon-svg-cta-go-arrow-circle-line pc-brand">
                    <use xlinkHref="#icon-svg-cta-go-arrow-circle-line" />
                  </svg>
                  <span className="wd-ml-8">Shop Autodesk products</span>
                </a>
              </p>
            </div>
            <div className="text section">
              <p className="wd-font-14">
                <a
                  href="/free-trials"
                  data-wat-link="true"
                  data-wat-loc="content"
                  data-wat-val="cta: explore free trials"
                  className="wd-inline-icon"
                >
                  <svg className="wd-icon icon-svg-cta-go-arrow-circle-line pc-brand">
                    <use xlinkHref="#icon-svg-cta-go-arrow-circle-line" />
                  </svg>
                  <span className="wd-ml-8">Explore free trials</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
const DataError = (
  <div className="responsive-grid section rootDataError rootError">
    <div className="row wd-ph-20">
      <div className="large-8 grid-columns">
        <div className="responsive-column-0 responsive-column">
          <div className="column-content parsys">
            <div className="text section">
              <p className="wd-color-dark wd-bbw-1 wd-bc-smoke wd-pb-12 wp-upper-line"><b>Page error</b></p>
            </div>
            <div className="heading section">
              <h2 className="wd-normal wd-mb-10 wd-mt-12">
                Something went wrong.
              </h2>
            </div>
            <div className="text section">
              <p className="wd-mt-0 wd-mb-32 wd-font-14" style={{ lineHeight: '20px !important' }}>
                An error occurred while loading this content. Reload the page to try again. If you continue to have trouble viewing this content, contact
                {' '}
                <a href="https://www.autodesk.com/certification/support#autodeskSupport">Autodesk Support</a>
                .
              </p>
            </div>
            <div className="text section">
              <p className="wd-font-14">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  onKeyDown={goBack}
                  onClick={goBack}
                  data-wat-link="true"
                  data-wat-loc="content"
                  data-wat-val="cta: return to previous page"
                  className="wd-inline-icon"
                >
                  <svg className="wd-icon icon-svg-cta-go-arrow-circle-line pc-brand">
                    <use xlinkHref="#icon-svg-cta-go-arrow-circle-line" />
                  </svg>
                  <span className="wd-ml-8">Return to the previous page</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
const BrowserError = (
  <div className="responsive-grid section rootBrowser rootError" style={{ backgroundPosition: `${Math.max((document?.body?.offsetWidth / 2) - 260, 300)}px -130px` }}>
    <div className="row wd-ph-20">
      <div className="large-8 grid-columns">
        <div className="responsive-column-0 responsive-column">
          <div className="column-content parsys">
            <div className="text section">
              <p className="wd-color-dark wd-bbw-1 wd-bc-smoke wd-pb-12 wp-upper-line"><b>Unsupported Browser</b></p>
            </div>
            <div className="heading section">
              <h2 className="wd-normal wd-mb-10 wd-mt-12">
                Your browser is not supported
              </h2>
            </div>
            <div className="text section">
              <p className="wd-mt-0 wd-mb-32 wd-font-14" style={{ lineHeight: '20px !important' }}>
                You are using a web browser we do not currently support.  For the best experience, use one of these options:
              </p>
            </div>
            <div className="text section">
              <p className="wd-font-14 wd-bs-dashed wd-bbw-1 wd-bc-smoke wd-pb-12">
                <a
                  href="https://www.google.com/chrome/"
                  data-wat-link="true"
                  data-wat-loc="content"
                  data-wat-val="cta: Google Chrome"
                  className="wd-inline-icon"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg className="wd-icon icon-svg-cta-go-arrow-circle-line pc-brand ">
                    <use xlinkHref="#icon-svg-cta-go-arrow-circle-line" />
                  </svg>
                  <span className="wd-ml-8">Google Chrome</span>
                </a>
              </p>
            </div>
            <div className="text section">
              <p className="wd-font-14 wd-bs-dashed wd-bbw-1 wd-bc-smoke wd-pb-12">
                <a
                  href="https://www.microsoft.com/en-us/edge"
                  data-wat-link="true"
                  data-wat-loc="content"
                  data-wat-val="cta: Microsoft Edge"
                  className="wd-inline-icon"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg className="wd-icon icon-svg-cta-go-arrow-circle-line pc-brand">
                    <use xlinkHref="#icon-svg-cta-go-arrow-circle-line" />
                  </svg>
                  <span className="wd-ml-8">Microsoft Edge</span>
                </a>
              </p>
            </div>
            <div className="text section">
              <p className="wd-font-14 wd-bs-dashed wd-bbw-1 wd-bc-smoke wd-pb-12">
                <a
                  href="https://www.mozilla.org/en-US/firefox/"
                  data-wat-link="true"
                  data-wat-loc="content"
                  data-wat-val="cta: Mozilla Firefox"
                  className="wd-inline-icon"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg className="wd-icon icon-svg-cta-go-arrow-circle-line pc-brand">
                    <use xlinkHref="#icon-svg-cta-go-arrow-circle-line" />
                  </svg>
                  <span className="wd-ml-8">Mozilla Firefox</span>
                </a>
              </p>
            </div>
            <div className="text section">
              <p className="wd-font-14">
                <a
                  href="https://support.apple.com/downloads/safari"
                  data-wat-link="true"
                  data-wat-loc="content"
                  data-wat-val="cta: Apple Safari"
                  className="wd-inline-icon"
                  style={{ display: 'inline-block' }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg className="wd-icon icon-svg-cta-go-arrow-circle-line pc-brand">
                    <use xlinkHref="#icon-svg-cta-go-arrow-circle-line" />
                  </svg>
                  <span className="wd-ml-8">Apple Safari</span>
                </a>
                {' '}
                (macOS only)
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const getView = (errorState) => {
  switch (errorState.errorType) {
    case ERROR_TYPE_404:
      return NotFound;
    case ERROR_TYPE_BROWSER:
      return BrowserError;
    default:
      return DataError;
  }
};

const ErrorView = () => {
  const errorState = useSelector(getErrorState);
  const dispatch = useDispatch();

  clearState = () => {
    dispatch(clearErrorState());
  };

  return (
    <RootElement>

      {getView(errorState)}
    </RootElement>
  );
};

const RootElement = styled.div`
@media only screen and (max-width: 40em) { 
  .rootError {
    background: url(/content/dam/autodesk/www/404/2020-update/error-404-background-1000x1000.png) no-repeat;
    background-position: calc(50% + 230px) calc(0% + 20px);
  }
  h2 {
    font-size: 32px !important;
    line-height: 40px !important;
  }
}

@media only screen and (min-width: 40.063em) and (max-width: 64em)  {
  .rootError {
    background: url(/content/dam/autodesk/www/404/2020-update/error-404-background-1000x1000.png) no-repeat;
    background-position: calc(50% + 260px) calc(50% + 100px);
  }
  h2 {
    font-size: 36px !important;
    line-height: 44px !important;
  }
}

@media only screen and (min-width: 64.063em){
  .rootError {
    background: url(/content/dam/autodesk/www/404/2020-update/error-404-background-1000x1000.png) no-repeat;
    background-position: calc(50% + 330px) calc(50%);
  }
  h2 {
    font-size: 36px !important;
    line-height: 44px !important;
  }
}

h2 {
  color: #000;
}
div.rootError {
  height: 750px;

 .column-content {
    max-width: 600px;
    display: table-cell;
    vertical-align: top;
    padding-top: 3em;
  }
}
`;

export default ErrorView;
