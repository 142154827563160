import { createSlice } from '@reduxjs/toolkit';
import produce from 'immer';

export const embeddedContentSlice = createSlice({
  name: 'embeddedContent',
  initialState: {
    iframeHeight: '100%',
    isLoaderVisible: true,
    isCongratsVisible: false,
    unitNav: { visible: false, target: null },
    renderIframe: false,
  },
  reducers: {
    setCongratsVisible: (state, action) => {
      const newState = produce(state, (draftState) => {
        draftState.isCongratsVisible = action.payload;
      });
      return newState;
    },
    setRenderIframe: (state, action) => {
      const newState = produce(state, (draftState) => {
        draftState.renderIframe = action.payload;
      });
      return newState;
    },
    setUnitNav: (state, action) => {
      const newState = produce(state, (draftState) => {
        draftState.unitNav = action.payload;
      });
      return newState;
    },
    setIframeHeight: (state, action) => {
      const newState = produce(state, (draftState) => {
        draftState.iframeHeight = `${action.payload}px`;
        draftState.isLoaderVisible = false;
      });
      return newState;
    },
    setLoaderVisibility: (state, action) => {
      const newState = produce(state, (draftState) => {
        draftState.isLoaderVisible = action.payload;
      });
      return newState;
    },
  },
});
export const {
  setIframeHeight, setLoaderVisibility, setCongratsVisible, setUnitNav, setRenderIframe,
} = embeddedContentSlice.actions;
export const getIframeHeight = (state) => state.embeddedContent.iframeHeight;
export const isLoaderVisible = (state) => state.embeddedContent.isLoaderVisible;
export const isCongratsVisible = (state) => state.embeddedContent.isCongratsVisible;
export const renderIframe = (state) => state.embeddedContent.renderIframe;
export const isUnitNavVisible = (state) => state.embeddedContent.unitNav.visible;
export const getUnitNavTarget = (state) => state.embeddedContent.unitNav.target;

export default embeddedContentSlice.reducer;
