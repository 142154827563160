import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import produce from 'immer';
import logger from '../app/common/logger';
import { learnOutlineSliceInitialState, learnOutlineSliceName } from '../app/common/constants';
import { getOutlineState } from './universalDataFetchers';

export const fetchOutlineData = createAsyncThunk(
  'outlineData',
  ({
    match, env, useMocks, lmsHeaders,
  }, { rejectWithValue }) => {
    const { course } = match.params;
    return getOutlineState({
      course, env, useMocks, lmsHeaders,
    }).catch((err) => rejectWithValue(err));
  },
);
export const outlineSlice = createSlice({
  name: learnOutlineSliceName,
  initialState: learnOutlineSliceInitialState,
  reducers: {
    setOutlineData: (state, action) => {
      const newState = produce(state, (draftState) => {
        draftState.outline = action.payload;
      });
      return newState;
    },
    toggleOpenCourseSection: (state, action) => {
      const newState = produce(state, (draftState) => {
        if (!draftState.blockState) {
          draftState.blockState = {};
        }
        if (typeof draftState.blockState[action.payload] === 'undefined') {
          draftState.blockState[action.payload] = { isOpen: true };
        } else {
          draftState.blockState[action.payload].isOpen = !draftState.blockState[action.payload].isOpen;
        }
      });

      return newState;
    },
  },
  extraReducers: {

    [fetchOutlineData.pending]: (state) => ({
      ...state,
      isLoaderVisible: true,
    }),
    [fetchOutlineData.fulfilled]: (state, action) => {
      const data = action?.payload || {};
      if (!data.error) {
        const newState = produce(state, (draftState) => {
          draftState.outline = data;
          draftState.isLoaderVisible = false;
        });

        return newState;
      }

      return state;
    },
    [fetchOutlineData.rejected]: (state, action) => {
      logger.error('Error in fetchOutlineData: ', action.payload);
      return {
        ...state,

      };
    },
  },
});
export const { toggleOpenCourseSection, setOutlineData } = outlineSlice.actions;

export const getOutline = (state) => state.outlineData.outline;
export const getProblemPassValue = (state) => state.outlineData.problemPassValue;
export const isBlockOpen = (state, id) => {
  if (state.outlineData.blockState && state.outlineData.blockState[id]) {
    return state.outlineData.blockState[id].isOpen;
  }

  return false;
};

export const areBlocksOpen = (state, ids = []) => {
  if (state.outlineData.blockState && ids.some((blockId) => typeof state.outlineData.blockState[blockId] !== 'undefined')) {
    return ids.some((blockId) => isBlockOpen(state, blockId));
  }
  return undefined;
};

export const isLoaderVisible = (state) => state.outlineData.isLoaderVisible;

export default outlineSlice.reducer;
