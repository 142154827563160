// Routes with subroutes possible see: https://reactrouter.com/web/example/route-config
// Note: subroute components would display on page WITH parent route component

import { matchPath } from 'react-router-dom';
import {
  COMPONENT_OUTLINE,
  COMPONENT_INDEX,
  COMPONENT_NOTFOUND,
  COMPONENT_EMBEDDEDCONTENT,
  COMPONENT_MYDASHBOARD,
  PERF_ENV,
} from './constants';
import { setChildProperty } from './object';
import { getOutlineState } from '../../store/universalDataFetchers';

// Common route definition used by SSR and CSR
const routes = [
  {
    path: '/',
    componentKey: COMPONENT_INDEX,
    exact: true,
  },
  {
    path: '/index.html',
    componentKey: COMPONENT_INDEX,
    exact: true,
  },
  {
    path: '/course/:course',
    componentKey: COMPONENT_OUTLINE,
    exact: true,
    pageLevelDataCall: getOutlineState,
    storeDataObject: 'outlineData.outline',
    mockDataPath: '../../mocks/fullStateMock.json',
  },
  {
    path: '/course/:course/:item',
    componentKey: COMPONENT_EMBEDDEDCONTENT,
    pageLevelDataCall: getOutlineState,
    storeDataObject: 'outlineData.outline',
    exact: true,
    mockDataPath: '../../mocks/fullStateMock.json',
  },
  {
    path: '/dashboard',
    componentKey: COMPONENT_MYDASHBOARD,
    exact: true,
  },
  {
    path: '*',
    componentKey: COMPONENT_NOTFOUND,
    status: 404,
  },

];

export default routes;

// Called from SSR /server/lib/render.tsx to fetch associated server-side data for a route.
export const getDataForPath = async ({
  path, isSSR = false, env, useMocks = false, lmsHeaders = {},
}) => {
  const matchedRoute = routes
    .map((route) => ({ route, match: matchPath(path.replace(process.env.PUBLIC_URL, ''), route) }))
    .filter((combined) => combined.match !== null);
  let returnedData;

  if (env !== PERF_ENV) {
    if (matchedRoute[0] && matchedRoute[0].route.pageLevelDataCall) {
      const { course } = matchedRoute[0].match.params;
      returnedData = await matchedRoute[0].route.pageLevelDataCall({
        course, env, useMocks, lmsHeaders,
      });
    }
  } else if (matchedRoute[0] && matchedRoute[0].route.mockDataPath) {
    returnedData = await import(matchedRoute[0].route.mockDataPath);
  }

  if (isSSR && returnedData) {
    if (!returnedData.error) {
      // Handle wrapping data with store slice structure for SSR
      returnedData = setChildProperty(returnedData, matchedRoute[0].route.storeDataObject, {});
      returnedData[matchedRoute[0].route.storeDataObject.split('.')[0]].isLoaderVisible = false;
    }
  }

  return returnedData;
};
