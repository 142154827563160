import window from 'global';
import { stripTrailingSlash } from './utilities';
import { ROUTER_BASENAME } from './constants';

export default (history) => {
  const curPath = stripTrailingSlash(window.location.href);
  switch (true) {
    case curPath.includes(`${ROUTER_BASENAME}/dashboard`): {
      window.location = '/certification';
      break;
    }
    case curPath.includes('/block-v1'): {
      history.push(curPath.substring(curPath.indexOf('/course'), curPath.indexOf('/block-v1')));
      break;
    }
    // eslint-disable-next-line no-empty
    default: {}
  }
};
