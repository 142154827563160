import { createSlice } from '@reduxjs/toolkit';
import window from 'global/window';
import produce from 'immer';

export const errorSlice = createSlice({
  name: 'errorData',
  initialState: {
    hasError: false,
    errorType: null,
    metaData: null,
  },
  reducers: {
    setErrorState: (state, action) => {
      window.scrollTo(0, 0);
      const newState = produce(state, (draftState) => {
        draftState.hasError = true;
        draftState.errorType = action?.payload?.type;
        draftState.metaData = action?.payload?.meta;
      });
      return newState;
    },
    clearErrorState: (state) => {
      const newState = produce(state, (draftState) => {
        draftState.hasError = false;
        draftState.errorType = null;
        draftState.metaData = null;
      });
      return newState;
    },

  },

});

export const {
  setErrorState, clearErrorState,
} = errorSlice.actions;

export const getErrorState = (state) => state.errorData;

export default errorSlice.reducer;
