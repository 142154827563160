import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { hydrate } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import window from 'global';
import { matchPath } from 'react-router';
import { generateStore } from './store/store';
import { PRELOADED_STATE_KEY, AWS_ENV_VARS, ROUTER_BASENAME } from './app/common/constants';
import ClientComponentLoader from './app/common/clientComponentLoader';
import processEmbeddedContentMessage from './app/common/processEmbeddedContentMessage';
import { EnvProvider } from './app/common/envContext';
import logger from './app/common/logger';

import App from './App';
import * as serviceWorker from './serviceWorker.ts';
import routes from './app/common/routerConfig';

let preloadedState = {};
// Grab the state from a global variable injected into the server-generated HTML
// eslint-disable-next-line no-underscore-dangle
preloadedState = window[PRELOADED_STATE_KEY];

logger.info('initializing app');

// Attach event listener for EdX child window events
window.addEventListener('message', processEmbeddedContentMessage, false);

// Create Redux store with initial state
const store = generateStore(preloadedState);
const defaultEnvData = {};
Object.keys(AWS_ENV_VARS).forEach((key) => {
  defaultEnvData[key] = AWS_ENV_VARS[key].default;
});
const env = preloadedState?.envData || defaultEnvData;
env.COMPONENT_LOADER = ClientComponentLoader;

const hydrateApplication = () => {
  hydrate(
    <HelmetProvider>
      <Provider store={store}>
        <BrowserRouter basename={ROUTER_BASENAME}>
          <EnvProvider value={env}>
            <App />
          </EnvProvider>
        </BrowserRouter>
      </Provider>
    </HelmetProvider>,
    document.getElementById('root'),
  );
  const resetFixedHeaderSpacing = () => {
    const header = document.getElementsByTagName('header')[0];
    const appNav = document.getElementById('appNav');
    const root = document.getElementById('root').firstElementChild;
    if (appNav) {
      appNav.style.top = header ? `${header.offsetHeight}px` : '0px';
      if (root) {
        root.style.paddingTop = header ? `${header.offsetHeight + appNav.offsetHeight}px` : `${appNav.offsetHeight}px`;
      }
    }
  };

  window.addEventListener('resize', () => {
    resetFixedHeaderSpacing();
  });

  resetFixedHeaderSpacing();

  // TODO: Resolve mobile site header reflow event
  setTimeout(resetFixedHeaderSpacing, 100);
};

const filtered = routes.filter((route) => matchPath(document.location.pathname, {
  path: ROUTER_BASENAME + route.path,
  exact: route.exact || false,
  strict: route.strict || false,
}) !== null);

if (filtered.length) {
  // Found a matching component for the route.  Wait for it to load before attempting hydration

  logger.info('Preloading component: ', filtered[0].componentKey);
  ClientComponentLoader[filtered[0].componentKey].load().then(() => {
    hydrateApplication();
  });
} else {
  hydrateApplication();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
