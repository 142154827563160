import window from 'global/window';

// export const FILTER_GROUP_SEPERATOR = '|';
// export const FILTER_LIST_SEPERATOR = ',';

export const OXYGEN_REDIRECT_URL_KEY = 'autodesk_oxygen_redir_url';
export const OXYGEN_RESPONSE_URL = `${window?.location?.origin}/certification/learn/oxygen_openid_response.html`;

export const LRS_VERB_COMPLETED = 'completed';
export const LRS_VERB_STARTED = 'started';

export const LMS_FORMAT_PRETEST = 'Pretest';

export const PRELOADED_STATE_KEY = '__PRELOADED_STATE__';

export const DEV_ENV = 'dev';
export const STAGE_ENV = 'stg';
export const PROD_ENV = 'prod';
export const PERF_ENV = 'perf';
export const UNIVERSAL_HEADER = 'UNIVERSAL_HEADER';

export const LOG_LEVEL = 'LOG_LEVEL';
export const LOG_LEVEL_DEBUG = 'debug';
export const LOG_LEVEL_INFO = 'info';
export const LOG_LEVEL_WARN = 'warn';
export const LOG_LEVEL_ERROR = 'error';

export const LOGGER_URL = 'LOGGER_URL';
export const ENVIRONMENT = 'ENVIRONMENT';
export const REQUEST_FLAGS = 'requestFlags';
export const LMS_HEADERS = 'LMS_HEADERS';
export const HOST = 'HOST';
export const USE_MOCKS = 'useMocks';
export const SHARE_PREFIX = 'SHARE_PREFIX';
export const EDX_HOST = 'EDX_HOST';
export const CLARUS_LINK = 'CLARUS_LINK';
export const LL_PRODUCER_API = 'LL_PRODUCER_API';
export const LL_CONSUMER_API = 'LL_CONSUMER_API';
export const LL_RECORD_FILTER = 'LL_RECORD_FILTER';
export const LMS_ID_API = 'LMS_ID_API';
export const LMS_CERT_API = 'LMS_CERT_API';

export const LIB_EVENT = 'EVENT';
export const LIB_EVENT_RESIZE = 'RESIZE';
export const LIB_EVENT_ERROR = 'ERROR';
export const LIB_EVENT_ERROR_CODE = 'ERROR_CODE';
export const LIB_EVENT_ERROR_MESSAGE = 'ERROR_MESSAGE';
export const LIB_EVENT_ERROR_TIMESTAMP = 'ERROR_TIMESTAMP';
export const LIB_EVENT_EDX_LOAD = 'EDX_LOAD';
export const LIB_EVENT_PROBLEM_COMPLETE = 'PROBLEM_COMPLETE';
export const LIB_EVENT_VIDEO_PROGRESS = 'VIDEO_PROGRESS';
export const LIB_EVENT_VALUE = 'VALUE';
export const LIB_EVENT_URL = 'URL';
export const LIB_EVENT_PLUGIN_RESIZE = 'plugin.resize'; // Native EdX event
export const LIB_EVENT_LINK_CLICK = 'LINK_CLICK';

export const OTHER_COURSE_SETTINGS_METADATA = 'courseMetadata';
export const OTHER_COURSE_SETTINGS_COMPLETED = 'courseCompletionMsg';
export const OTHER_COURSE_SETTINGS_EFFORT = 'effort';
export const OTHER_COURSE_SETTINGS_EFFORT_DISPLAY = 'displayName';
export const OTHER_COURSE_SETTINGS_EFFORT_MINUTES = 'minutes';
export const OTHER_COURSE_SETTINGS_AREA_OF_STUDY = 'areaOfStudy';
export const OTHER_COURSE_SETTINGS_TYPE = 'type';
export const OTHER_COURSE_SETTINGS_LEVEL = 'level';
export const OTHER_COURSE_SETTINGS_PRODUCTS = 'products';
export const OTHER_COURSE_SETTINGS_PRODUCTS_CODE = 'plc';
export const OTHER_COURSE_SETTINGS_PRODUCTS_DISPLAY = 'displayName';
export const OTHER_COURSE_SETTINGS_IMAGE_CAPTION = 'descImgCaption';

export const COMPONENT_OUTLINE = 'outline';
export const COMPONENT_INDEX = 'index';
export const COMPONENT_NOTFOUND = 'notFound';
export const COMPONENT_EMBEDDEDCONTENT = 'embeddedContent';
export const COMPONENT_MYDASHBOARD = 'myDashboard';

export const BLOCK_TYPE_UNIT = 'vertical';
export const BLOCK_TYPE_COURSE = 'course';
export const BLOCK_TYPE_PROBLEM = 'problem';

export const OBJECT_TYPE_UNIT = 'resource';
export const OBJECT_TYPE_PROBLEM = 'assessment';

export const VALID_AWS_ENVS = [DEV_ENV, STAGE_ENV, PROD_ENV, PERF_ENV];
export const VALID_LOG_LEVELS = [LOG_LEVEL_DEBUG, LOG_LEVEL_INFO, LOG_LEVEL_WARN, LOG_LEVEL_ERROR];

export const ERROR_TYPE_404 = '404';
export const ERROR_TYPE_XHR = 'XHR';
export const ERROR_TYPE_IFRAME = 'IFRAME';
export const ERROR_TYPE_BROWSER = 'BROWSER';

export const AA_EVENT_COURSE_START = 'start';
export const AA_EVENT_COURSE_CONTINUE = 'continue';
export const AA_EVENT_COURSE_COMPLETE = 'complete';

export const ROUTER_BASENAME = '/certification/learn';

export const LOCAL_STORAGE_LRS_KEY = 'gnosis.learningRecords';

export const RETRY_COUNT = 2;

/*
 This object is a key/value list of AWS Lambda Environment Variables required for the application.
 The value of each key is an object that contains:
    default (required): default value for this key if it is not present in the env
    validator (optional): a function to validate the value before setting it.  This function should
    return a boolean value.  If false the application will use the default value instead of the value
    that failed validation.
 */
export const AWS_ENV_VARS = {
  ENVIRONMENT: {
    default: DEV_ENV,
    validator: (v) => VALID_AWS_ENVS.includes(v),
  },
  LOG_LEVEL: {
    default: LOG_LEVEL_ERROR,
    validator: (v) => VALID_LOG_LEVELS.includes(v),
  },
  LOGGER_URL: {},

};

/*
 This object contains Constants who's values are relative to the environment the application is running in.
 */
export const ENVIRONMENT_CONSTANTS = {
  dev: {
    ENVIRONMENT: DEV_ENV,
    EDX_HOST: 'https://api.upskill-dev.autodesk.com',
    LOG_LEVEL: 'debug',
    LL_RECORD_FILTER: 'https://www.upskill-dev.autodesk.com',
    LL_PRODUCER_API: 'https://api.upskill-dev.autodesk.com/lrs/producer',
    LL_CONSUMER_API: 'https://api.upskill-dev.autodesk.com/lrs/consumer',
    SHARE_PREFIX: 'https://app.upskill-dev.autodesk.com',
    CLARUS_LINK: 'https://autodesk.clarus-stage.com',
    LMS_ID_API: 'https://api.upskill-dev.autodesk.com/api/third_party_auth/v0/providers/oa2-autodesk-oauth2/users?remote_id=',
    LMS_CERT_API: 'https://api.upskill-dev.autodesk.com/api/certificates/v0/generate/',
  },
  stg: {
    ENVIRONMENT: STAGE_ENV,
    EDX_HOST: 'https://api.upskill-stg.autodesk.com',
    LOG_LEVEL: 'warn',
    LL_RECORD_FILTER: 'https://www.upskill-stg.autodesk.com',
    LL_PRODUCER_API: 'https://api.upskill-stg.autodesk.com/lrs/producer',
    LL_CONSUMER_API: 'https://api.upskill-stg.autodesk.com/lrs/consumer',
    SHARE_PREFIX: 'https://app.upskill-stg.autodesk.com',
    CLARUS_LINK: 'https://autodesk.clarus-stage.com',
    LMS_ID_API: 'https://api.upskill-stg.autodesk.com/api/third_party_auth/v0/providers/oa2-autodesk-oauth2/users?remote_id=',
    LMS_CERT_API: 'https://api.upskill-stg.autodesk.com/api/certificates/v0/generate/',
  },
  prod: {
    ENVIRONMENT: PROD_ENV,
    EDX_HOST: 'https://api.upskill.autodesk.com',
    LOG_LEVEL: 'error',
    LL_RECORD_FILTER: 'https://www.upskill.autodesk.com',
    LL_PRODUCER_API: 'https://api.upskill.autodesk.com/lrs/producer',
    LL_CONSUMER_API: 'https://api.upskill.autodesk.com/lrs/consumer',
    SHARE_PREFIX: 'https://www.autodesk.com/certification/learn',
    CLARUS_LINK: 'https://autodesk.useclarus.com',
    LMS_ID_API: 'https://api.upskill.autodesk.com/api/third_party_auth/v0/providers/oa2-autodesk-oauth2/users?remote_id=',
    LMS_CERT_API: 'https://api.upskill.autodesk.com/api/certificates/v0/generate/',
  },
  perf: {
    ENVIRONMENT: PROD_ENV,
    EDX_HOST: 'https://api.upskill-prd.autodesk.com',
    LOG_LEVEL: 'error',
    LL_RECORD_FILTER: 'https://www.upskill-stg.autodesk.com',
    LL_PRODUCER_API: 'https://api.upskill-stg.autodesk.com/lrs/producer',
    LL_CONSUMER_API: 'https://api.upskill-stg.autodesk.com/lrs/consumer',
    SHARE_PREFIX: 'https://app.upskill-stg.autodesk.com',
    CLARUS_LINK: 'https://autodesk.clarus-stage.com',
    LMS_ID_API: 'https://api.upskill-stg.autodesk.com/api/third_party_auth/v0/providers/oa2-autodesk-oauth2/users?remote_id=',
    LMS_CERT_API: 'https://api.upskill-stg.autodesk.com/api/certificates/v0/generate/',
  },
};

// Add ENVIRONMENT_CONSTANTS to AWS_ENV_VARS to allow for overrides
Object.keys(ENVIRONMENT_CONSTANTS.dev).forEach((key) => {
  AWS_ENV_VARS[key] = AWS_ENV_VARS[key] || {};
});

export const learnOutlineSliceInitialState = {
  outline: {},
  isLoaderVisible: true,
  problemPassValue: 75,
};
export const learnOutlineSliceName = 'outlineData';
