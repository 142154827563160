import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import window from 'global/window';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getErrorState, clearErrorState } from '../../store/errorSlice';
import { setRenderIframe } from '../../store/embeddedContentSlice';
import { ERROR_TYPE_BROWSER } from '../common/constants';

const AppNav = () => {
  const [navLinkClasses, setNavLinkClasses] = useState(['visibility-target-visible', 'visibility-target-hidden']);
  const [navLinkParentClass, setNavLinkParentClass] = useState(['visibility-visible', 'visibility-hidden']);
  const handleMobileNav = () => {
    setNavLinkParentClass([...navLinkParentClass.reverse()]);
    setNavLinkClasses([...navLinkClasses.reverse()]);
  };

  const closeOpenMobileNav = () => {
    if (window.innerWidth <= 640 && navLinkClasses[0] === 'visibility-target-visible') {
      handleMobileNav();
    }
  };

  useEffect(() => {
    // Force mobile nav closed on start
    closeOpenMobileNav();
  }, []);

  const dispatch = useDispatch();
  const { errorType } = useSelector(getErrorState);
  const clear = () => {
    // Reset iframe state
    dispatch(setRenderIframe(false));
    // Close mobile nav if navigating within SPA
    closeOpenMobileNav();
    // Don't clear incompatible browser error on navigation
    if (errorType !== ERROR_TYPE_BROWSER) {
      // Do clear all other error states
      dispatch(clearErrorState());
    }
  };
  return (
    <RootElement className="local-navigation navigation section local-overrides" id="appNav">
      <div
        className="clearfix local-navigation-outer  wd-ln-themed wd-ln-dark pc-brand"
        data-wat-link-section="contextnav"
      >
        <div className="local-navigation-container">
          <div className="local-navigation-name" id="local-navigation-max">
            <div className="row">
              <p className="wd-uppercase wd-ma-0 wd-font-19">CERTIFICATION</p>
              <a
                href="#"
                data-visibility-target=".local-navigation-links"
                className={`nav-toggle nav-toggle-primary ${navLinkParentClass[0]}  nav-toggle-authoredTitle`}
                onClick={handleMobileNav}
              >
                <i
                  className="icon-arrow-dropdown"
                />
              </a>
            </div>
          </div>
          <div className={`local-navigation-links ${navLinkClasses[0]}`}>
            <div className="row">
              <nav>
                <ul className="local-navigation-list">
                  <li className="nav-item" data-wat-val="">
                    <a href="/certification/overview" className="nav-link">
                      Overview
                    </a>
                  </li>
                  <li className="nav-item" data-wat-val="">
                    <a href="/certification/all-certifications" className="nav-link">
                      Certifications
                    </a>
                  </li>
                  <li className="nav-item" data-wat-val="">
                    <a href="/certification/learning-pathways" className="nav-link">
                      Learning Pathways
                    </a>
                  </li>
                  <li className={`nav-item ${navLinkParentClass}`} data-wat-val="">
                    <a href="/certification/support" className="nav-link">
                      <div className="nav-link-text">Support &amp; Policies </div>
                    </a>
                  </li>
                  <li className="nav-item" data-wat-val="">
                    <NavLink activeClassName="nav-link-selected" to="/dashboard" onClick={clear} onKeyDown={clear} className="nav-link">
                      My Dashboard
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </RootElement>

  );
};

const RootElement = styled.div`
  position: fixed; 
  z-index: 5;
  width: 100%;
  top: 53px;
  
  &.local-overrides {
    .wd-ln-dark .local-navigation-name p {color:#fff; font-size:1.9rem; line-height:21px}
  }

`;

export default AppNav;
