/* eslint-disable */
/*! dhx-auth - v1.2.0
    Copyright 2020 Autodesk, Inc. All rights reserved. */
!function(e, t) {



  'object' == typeof exports && 'undefined' != typeof module ? module.exports = t() : 'function' == typeof define && define.amd ? define('DHXOxygen', t) : (e = e || self).DHXOxygen = t()
}(this, (function() {
  'use strict'
  /* Begin: Added section by Gnosis Team */
  let window = require('global');
  /* End: Added section by Gnosis Team */
  function e(e, t) {
    return e(t = { exports: {} }, t.exports), t.exports
  }

  var t = e((function(e) {
    var t = e.exports = 'undefined' != typeof window && window.Math == Math ? window : 'undefined' != typeof self && self.Math == Math ? self : Function('return this')()
    'number' == typeof __g && (__g = t)
  })), n = function(e) {
    if ('function' != typeof e) throw TypeError(e + ' is not a function!')
    return e
  }, o = function(e, t, o) {
    if (n(e), void 0 === t) return e
    switch (o) {
      case 1:
        return function(n) {
          return e.call(t, n)
        }
      case 2:
        return function(n, o) {
          return e.call(t, n, o)
        }
      case 3:
        return function(n, o, r) {
          return e.call(t, n, o, r)
        }
    }
    return function() {
      return e.apply(t, arguments)
    }
  }, r = {}.toString, i = function(e) {
    return r.call(e).slice(8, -1)
  }, s = e((function(e) {
    var t = e.exports = { version: '2.6.11' }
    'number' == typeof __e && (__e = t)
  })), a = (s.version, e((function(e) {
    var n = t['__core-js_shared__'] || (t['__core-js_shared__'] = {});
    (e.exports = function(e, t) {
      return n[e] || (n[e] = void 0 !== t ? t : {})
    })('versions', []).push({ version: s.version, mode: 'global', copyright: '© 2019 Denis Pushkarev (zloirock.ru)' })
  }))), c = 0, u = Math.random(), p = function(e) {
    return 'Symbol('.concat(void 0 === e ? '' : e, ')_', (++c + u).toString(36))
  }, f = e((function(e) {
    var n = a('wks'), o = t.Symbol, r = 'function' == typeof o;
    (e.exports = function(e) {
      return n[e] || (n[e] = r && o[e] || (r ? o : p)('Symbol.' + e))
    }).store = n
  })), d = f('toStringTag'), l = 'Arguments' == i(function() {
    return arguments
  }()), h = function(e) {
    var t, n, o
    return void 0 === e ? 'Undefined' : null === e ? 'Null' : 'string' == typeof (n = function(e, t) {
      try {
        return e[t]
      } catch (e) {
      }
    }(t = Object(e), d)) ? n : l ? i(t) : 'Object' == (o = i(t)) && 'function' == typeof t.callee ? 'Arguments' : o
  }, v = function(e) {
    return 'object' == typeof e ? null !== e : 'function' == typeof e
  }, m = function(e) {
    if (!v(e)) throw TypeError(e + ' is not an object!')
    return e
  }, y = function(e) {
    try {
      return !!e()
    } catch (e) {
      return !0
    }
  }, g = !y((function() {
    return 7 != Object.defineProperty({}, 'a', {
      get: function() {
        return 7
      },
    }).a
  })), _ = t.document, w = v(_) && v(_.createElement), b = function(e) {
    return w ? _.createElement(e) : {}
  }, x = !g && !y((function() {
    return 7 != Object.defineProperty(b('div'), 'a', {
      get: function() {
        return 7
      },
    }).a
  })), O = Object.defineProperty, j = {
    f: g ? Object.defineProperty : function(e, t, n) {
      if (m(e), t = function(e, t) {
        if (!v(e)) return e
        var n, o
        if ('function' == typeof (n = e.toString) && !v(o = n.call(e))) return o
        if ('function' == typeof (n = e.valueOf) && !v(o = n.call(e))) return o
        throw TypeError('Can\'t convert object to primitive value')
      }(t), m(n), x) try {
        return O(e, t, n)
      } catch (e) {
      }
      if ('get' in n || 'set' in n) throw TypeError('Accessors not supported!')
      return 'value' in n && (e[t] = n.value), e
    },
  }, k = g ? function(e, t, n) {
    return j.f(e, t, function(e, t) {
      return { enumerable: !1, configurable: !0, writable: !0, value: t }
    }(0, n))
  } : function(e, t, n) {
    return e[t] = n, e
  }, S = {}.hasOwnProperty, E = function(e, t) {
    return S.call(e, t)
  }, I = a('native-function-to-string', Function.toString), P = e((function(e) {
    var n = p('src'), o = ('' + I).split('toString')
    s.inspectSource = function(e) {
      return I.call(e)
    }, (e.exports = function(e, r, i, s) {
      var a = 'function' == typeof i
      a && (E(i, 'name') || k(i, 'name', r)), e[r] !== i && (a && (E(i, n) || k(i, n, e[r] ? '' + e[r] : o.join(String(r)))), e === t ? e[r] = i : s ? e[r] ? e[r] = i : k(e, r, i) : (delete e[r], k(e, r, i)))
    })(Function.prototype, 'toString', (function() {
      return 'function' == typeof this && this[n] || I.call(this)
    }))
  })), T = function(e, n, r) {
    var i, a, c, u, p = e & T.F, f = e & T.G, d = e & T.S, l = e & T.P, h = e & T.B,
      v = f ? t : d ? t[n] || (t[n] = {}) : (t[n] || {}).prototype, m = f ? s : s[n] || (s[n] = {}),
      y = m.prototype || (m.prototype = {})
    for (i in f && (r = n), r) c = ((a = !p && v && void 0 !== v[i]) ? v : r)[i], u = h && a ? o(c, t) : l && 'function' == typeof c ? o(Function.call, c) : c, v && P(v, i, c, e & T.U), m[i] != c && k(m, i, u), l && y[i] != c && (y[i] = c)
  }
  t.core = s, T.F = 1, T.G = 2, T.S = 4, T.P = 8, T.B = 16, T.W = 32, T.U = 64, T.R = 128
  var C, R, L, A = T, M = function(e, t, n, o) {
      try {
        return o ? t(m(n)[0], n[1]) : t(n)
      } catch (t) {
        var r = e.return
        throw void 0 !== r && m(r.call(e)), t
      }
    }, N = {}, D = f('iterator'), U = Array.prototype, H = function(e) {
      return void 0 !== e && (N.Array === e || U[D] === e)
    }, z = Math.ceil, q = Math.floor, F = Math.min, G = f('iterator'), B = s.getIteratorMethod = function(e) {
      if (null != e) return e[G] || e['@@iterator'] || N[h(e)]
    }, W = e((function(e) {
      var t = {}, n = {}, r = e.exports = function(e, r, i, s, a) {
        var c, u, p, f, d = a ? function() {
          return e
        } : B(e), l = o(i, s, r ? 2 : 1), h = 0
        if ('function' != typeof d) throw TypeError(e + ' is not iterable!')
        if (H(d)) {
          for (c = function(e) {
            return e > 0 ? F(function(e) {
              return isNaN(e = +e) ? 0 : (e > 0 ? q : z)(e)
            }(e), 9007199254740991) : 0
          }(e.length); c > h; h++) if ((f = r ? l(m(u = e[h])[0], u[1]) : l(e[h])) === t || f === n) return f
        } else for (p = d.call(e); !(u = p.next()).done;) if ((f = M(p, l, u.value, r)) === t || f === n) return f
      }
      r.BREAK = t, r.RETURN = n
    })), Z = f('species'), J = function(e, t, n) {
      var o = void 0 === n
      switch (t.length) {
        case 0:
          return o ? e() : e.call(n)
        case 1:
          return o ? e(t[0]) : e.call(n, t[0])
        case 2:
          return o ? e(t[0], t[1]) : e.call(n, t[0], t[1])
        case 3:
          return o ? e(t[0], t[1], t[2]) : e.call(n, t[0], t[1], t[2])
        case 4:
          return o ? e(t[0], t[1], t[2], t[3]) : e.call(n, t[0], t[1], t[2], t[3])
      }
      return e.apply(n, t)
    }, Q = t.document, X = Q && Q.documentElement, K = t.process, V = t.setImmediate, Y = t.clearImmediate,
    $ = t.MessageChannel, ee = t.Dispatch, te = 0, ne = {}, oe = function() {
      var e = +this
      if (ne.hasOwnProperty(e)) {
        var t = ne[e]
        delete ne[e], t()
      }
    }, re = function(e) {
      oe.call(e.data)
    }
  V && Y || (V = function(e) {
    for (var t = [], n = 1; arguments.length > n;) t.push(arguments[n++])
    return ne[++te] = function() {
      J('function' == typeof e ? e : Function(e), t)
    }, C(te), te
  }, Y = function(e) {
    delete ne[e]
  }, 'process' == i(K) ? C = function(e) {
    K.nextTick(o(oe, e, 1))
  } : ee && ee.now ? C = function(e) {
    ee.now(o(oe, e, 1))
  } : $ ? (L = (R = new $).port2, R.port1.onmessage = re, C = o(L.postMessage, L, 1)) : t.addEventListener && 'function' == typeof postMessage && !t.importScripts ? (C = function(e) {
    t.postMessage(e + '', '*')
  }, t.addEventListener('message', re, !1)) : C = 'onreadystatechange' in b('script') ? function(e) {
    X.appendChild(b('script')).onreadystatechange = function() {
      X.removeChild(this), oe.call(e)
    }
  } : function(e) {
    setTimeout(o(oe, e, 1), 0)
  })
  var ie = { set: V, clear: Y }, se = ie.set, ae = t.MutationObserver || t.WebKitMutationObserver, ce = t.process,
    ue = t.Promise, pe = 'process' == i(ce)

  function fe(e) {
    var t, o
    this.promise = new e((function(e, n) {
      if (void 0 !== t || void 0 !== o) throw TypeError('Bad Promise constructor')
      t = e, o = n
    })), this.resolve = n(t), this.reject = n(o)
  }

  var de = {
      f: function(e) {
        return new fe(e)
      },
    }, le = function(e) {
      try {
        return { e: !1, v: e() }
      } catch (e) {
        return { e: !0, v: e }
      }
    }, he = t.navigator, ve = he && he.userAgent || '', me = j.f, ye = f('toStringTag'), ge = f('species'),
    _e = f('iterator'), we = !1
  try {
    var be = [7][_e]()
    be.return = function() {
      we = !0
    }, Array.from(be, (function() {
      throw 2
    }))
  } catch (e) {
  }
  var xe, Oe, je, ke, Se, Ee, Ie = ie.set, Pe = function() {
      var e, n, o, r = function() {
        var t, r
        for (pe && (t = ce.domain) && t.exit(); e;) {
          r = e.fn, e = e.next
          try {
            r()
          } catch (t) {
            throw e ? o() : n = void 0, t
          }
        }
        n = void 0, t && t.enter()
      }
      if (pe) { o = function() {
        ce.nextTick(r)
      } } else if (!ae || t.navigator && t.navigator.standalone) if (ue && ue.resolve) {
        var i = ue.resolve(void 0)
        o = function() {
          i.then(r)
        }
      } else { o = function() {
        se.call(t, r)
      } } else {
        var s = !0, a = document.createTextNode('')
        new ae(r).observe(a, { characterData: !0 }), o = function() {
          a.data = s = !s
        }
      }
      return function(t) {
        var r = { fn: t, next: void 0 }
        n && (n.next = r), e || (e = r, o()), n = r
      }
    }(), Te = t.TypeError, Ce = t.process, Re = Ce && Ce.versions, Le = Re && Re.v8 || '', Ae = t.Promise,
    Me = 'process' == h(Ce), Ne = function() {
    }, De = Oe = de.f, Ue = !!function() {
      try {
        var e = Ae.resolve(1), t = (e.constructor = {})[f('species')] = function(e) {
          e(Ne, Ne)
        }
        return (Me || 'function' == typeof PromiseRejectionEvent) && e.then(Ne) instanceof t && 0 !== Le.indexOf('6.6') && -1 === ve.indexOf('Chrome/66')
      } catch (e) {
      }
    }(), He = function(e) {
      var t
      return !(!v(e) || 'function' != typeof (t = e.then)) && t
    }, ze = function(e, t) {
      if (!e._n) {
        e._n = !0
        var n = e._c
        Pe((function() {
          for (var o = e._v, r = 1 == e._s, i = 0, s = function(t) {
            var n, i, s, a = r ? t.ok : t.fail, c = t.resolve, u = t.reject, p = t.domain
            try {
              a ? (r || (2 == e._h && Ge(e), e._h = 1), !0 === a ? n = o : (p && p.enter(), n = a(o), p && (p.exit(), s = !0)), n === t.promise ? u(Te('Promise-chain cycle')) : (i = He(n)) ? i.call(n, c, u) : c(n)) : u(o)
            } catch (e) {
              p && !s && p.exit(), u(e)
            }
          }; n.length > i;) s(n[i++])
          e._c = [], e._n = !1, t && !e._h && qe(e)
        }))
      }
    }, qe = function(e) {
      Ie.call(t, (function() {
        var n, o, r, i = e._v, s = Fe(e)
        if (s && (n = le((function() {
          Me ? Ce.emit('unhandledRejection', i, e) : (o = t.onunhandledrejection) ? o({
            promise: e,
            reason: i,
          }) : (r = t.console) && r.error && r.error('Unhandled promise rejection', i)
        })), e._h = Me || Fe(e) ? 2 : 1), e._a = void 0, s && n.e) throw n.v
      }))
    }, Fe = function(e) {
      return 1 !== e._h && 0 === (e._a || e._c).length
    }, Ge = function(e) {
      Ie.call(t, (function() {
        var n
        Me ? Ce.emit('rejectionHandled', e) : (n = t.onrejectionhandled) && n({ promise: e, reason: e._v })
      }))
    }, Be = function(e) {
      var t = this
      t._d || (t._d = !0, (t = t._w || t)._v = e, t._s = 2, t._a || (t._a = t._c.slice()), ze(t, !0))
    }, We = function(e) {
      var t, n = this
      if (!n._d) {
        n._d = !0, n = n._w || n
        try {
          if (n === e) throw Te('Promise can\'t be resolved itself');
          (t = He(e)) ? Pe((function() {
            var r = { _w: n, _d: !1 }
            try {
              t.call(e, o(We, r, 1), o(Be, r, 1))
            } catch (e) {
              Be.call(r, e)
            }
          })) : (n._v = e, n._s = 1, ze(n, !1))
        } catch (e) {
          Be.call({ _w: n, _d: !1 }, e)
        }
      }
    }
  Ue || (Ae = function(e) {
    !function(e, t, n, o) {
      if (!(e instanceof t) || '_h' in e) throw TypeError('Promise: incorrect invocation!')
    }(this, Ae), n(e), xe.call(this)
    try {
      e(o(We, this, 1), o(Be, this, 1))
    } catch (e) {
      Be.call(this, e)
    }
  }, (xe = function(e) {
    this._c = [], this._a = void 0, this._s = 0, this._d = !1, this._v = void 0, this._h = 0, this._n = !1
  }).prototype = function(e, t, n) {
    for (var o in t) P(e, o, t[o], void 0)
    return e
  }(Ae.prototype, {
    then: function(e, t) {
      var o, r, i, s = De((o = Ae, void 0 === (i = m(this).constructor) || null == (r = m(i)[Z]) ? o : n(r)))
      return s.ok = 'function' != typeof e || e, s.fail = 'function' == typeof t && t, s.domain = Me ? Ce.domain : void 0, this._c.push(s), this._a && this._a.push(s), this._s && ze(this, !1), s.promise
    }, catch: function(e) {
      return this.then(void 0, e)
    },
  }), je = function() {
    var e = new xe
    this.promise = e, this.resolve = o(We, e, 1), this.reject = o(Be, e, 1)
  }, de.f = De = function(e) {
    return e === Ae || e === ke ? new je(e) : Oe(e)
  }), A(A.G + A.W + A.F * !Ue, { Promise: Ae }), (Se = Ae) && !E(Se = Se.prototype, ye) && me(Se, ye, {
    configurable: !0,
    value: 'Promise',
  }), Ee = t.Promise, g && Ee && !Ee[ge] && j.f(Ee, ge, {
    configurable: !0, get: function() {
      return this
    },
  }), ke = s.Promise, A(A.S + A.F * !Ue, 'Promise', {
    reject: function(e) {
      var t = De(this)
      return (0, t.reject)(e), t.promise
    },
  }), A(A.S + A.F * !Ue, 'Promise', {
    resolve: function(e) {
      return function(e, t) {
        if (m(e), v(t) && t.constructor === e) return t
        var n = de.f(e)
        return (0, n.resolve)(t), n.promise
      }(this, e)
    },
  }), A(A.S + A.F * !(Ue && function(e, t) {
    if (!we) return !1
    var n = !1
    try {
      var o = [7], r = o[_e]()
      r.next = function() {
        return { done: n = !0 }
      }, o[_e] = function() {
        return r
      }, function(e) {
        Ae.all(e).catch(Ne)
      }(o)
    } catch (e) {
    }
    return n
  }()), 'Promise', {
    all: function(e) {
      var t = this, n = De(t), o = n.resolve, r = n.reject, i = le((function() {
        var n = [], i = 0, s = 1
        W(e, !1, (function(e) {
          var a = i++, c = !1
          n.push(void 0), s++, t.resolve(e).then((function(e) {
            c || (c = !0, n[a] = e, --s || o(n))
          }), r)
        })), --s || o(n)
      }))
      return i.e && r(i.v), n.promise
    }, race: function(e) {
      var t = this, n = De(t), o = n.reject, r = le((function() {
        W(e, !1, (function(e) {
          t.resolve(e).then(n.resolve, o)
        }))
      }))
      return r.e && o(r.v), n.promise
    },
  })
  var Ze = {}
  Ze[f('toStringTag')] = 'z', Ze + '' != '[object z]' && P(Object.prototype, 'toString', (function() {
    return '[object ' + h(this) + ']'
  }), !0)
  var Je = {}.toString, Qe = function(e) {
    return Je.call(e).slice(8, -1)
  }, Xe = e((function(e) {
    var t = e.exports = { version: '2.6.11' }
    'number' == typeof __e && (__e = t)
  })), Ke = (Xe.version, e((function(e) {
    var t = e.exports = 'undefined' != typeof window && window.Math == Math ? window : 'undefined' != typeof self && self.Math == Math ? self : Function('return this')()
    'number' == typeof __g && (__g = t)
  }))), Ve = e((function(e) {
    var t = Ke['__core-js_shared__'] || (Ke['__core-js_shared__'] = {});
    (e.exports = function(e, n) {
      return t[e] || (t[e] = void 0 !== n ? n : {})
    })('versions', []).push({ version: Xe.version, mode: 'global', copyright: '© 2019 Denis Pushkarev (zloirock.ru)' })
  })), Ye = 0, $e = Math.random(), et = function(e) {
    return 'Symbol('.concat(void 0 === e ? '' : e, ')_', (++Ye + $e).toString(36))
  }, tt = e((function(e) {
    var t = Ve('wks'), n = Ke.Symbol, o = 'function' == typeof n;
    (e.exports = function(e) {
      return t[e] || (t[e] = o && n[e] || (o ? n : et)('Symbol.' + e))
    }).store = t
  })), nt = tt('toStringTag'), ot = 'Arguments' == Qe(function() {
    return arguments
  }()), rt = function(e) {
    return 'object' == typeof e ? null !== e : 'function' == typeof e
  }, it = function(e) {
    if (!rt(e)) throw TypeError(e + ' is not an object!')
    return e
  }, st = function(e) {
    try {
      return !!e()
    } catch (e) {
      return !0
    }
  }, at = !st((function() {
    return 7 != Object.defineProperty({}, 'a', {
      get: function() {
        return 7
      },
    }).a
  })), ct = Ke.document, ut = rt(ct) && rt(ct.createElement), pt = !at && !st((function() {
    return 7 != Object.defineProperty(ut ? ct.createElement('div') : {}, 'a', {
      get: function() {
        return 7
      },
    }).a
  })), ft = Object.defineProperty, dt = {
    f: at ? Object.defineProperty : function(e, t, n) {
      if (it(e), t = function(e, t) {
        if (!rt(e)) return e
        var n, o
        if ('function' == typeof (n = e.toString) && !rt(o = n.call(e))) return o
        if ('function' == typeof (n = e.valueOf) && !rt(o = n.call(e))) return o
        throw TypeError('Can\'t convert object to primitive value')
      }(t), it(n), pt) try {
        return ft(e, t, n)
      } catch (e) {
      }
      if ('get' in n || 'set' in n) throw TypeError('Accessors not supported!')
      return 'value' in n && (e[t] = n.value), e
    },
  }, lt = at ? function(e, t, n) {
    return dt.f(e, t, function(e, t) {
      return { enumerable: !1, configurable: !0, writable: !0, value: t }
    }(0, n))
  } : function(e, t, n) {
    return e[t] = n, e
  }, ht = {}.hasOwnProperty, vt = function(e, t) {
    return ht.call(e, t)
  }, mt = Ve('native-function-to-string', Function.toString), yt = e((function(e) {
    var t = et('src'), n = ('' + mt).split('toString')
    Xe.inspectSource = function(e) {
      return mt.call(e)
    }, (e.exports = function(e, o, r, i) {
      var s = 'function' == typeof r
      s && (vt(r, 'name') || lt(r, 'name', o)), e[o] !== r && (s && (vt(r, t) || lt(r, t, e[o] ? '' + e[o] : n.join(String(o)))), e === Ke ? e[o] = r : i ? e[o] ? e[o] = r : lt(e, o, r) : (delete e[o], lt(e, o, r)))
    })(Function.prototype, 'toString', (function() {
      return 'function' == typeof this && this[t] || mt.call(this)
    }))
  })), gt = {}
  gt[tt('toStringTag')] = 'z', gt + '' != '[object z]' && yt(Object.prototype, 'toString', (function() {
    return '[object ' + function(e) {
      var t, n, o
      return void 0 === e ? 'Undefined' : null === e ? 'Null' : 'string' == typeof (n = function(e, t) {
        try {
          return e[t]
        } catch (e) {
        }
      }(t = Object(e), nt)) ? n : ot ? Qe(t) : 'Object' == (o = Qe(t)) && 'function' == typeof t.callee ? 'Arguments' : o
    }(this) + ']'
  }), !0)
  var _t = function(e, t, n) {
    if (function(e) {
      if ('function' != typeof e) throw TypeError(e + ' is not a function!')
    }(e), void 0 === t) return e
    switch (n) {
      case 1:
        return function(n) {
          return e.call(t, n)
        }
      case 2:
        return function(n, o) {
          return e.call(t, n, o)
        }
      case 3:
        return function(n, o, r) {
          return e.call(t, n, o, r)
        }
    }
    return function() {
      return e.apply(t, arguments)
    }
  }, wt = function(e, t, n) {
    var o, r, i, s, a = e & wt.F, c = e & wt.G, u = e & wt.S, p = e & wt.P, f = e & wt.B,
      d = c ? Ke : u ? Ke[t] || (Ke[t] = {}) : (Ke[t] || {}).prototype, l = c ? Xe : Xe[t] || (Xe[t] = {}),
      h = l.prototype || (l.prototype = {})
    for (o in c && (n = t), n) i = ((r = !a && d && void 0 !== d[o]) ? d : n)[o], s = f && r ? _t(i, Ke) : p && 'function' == typeof i ? _t(Function.call, i) : i, d && yt(d, o, i, e & wt.U), l[o] != i && lt(l, o, s), p && h[o] != i && (h[o] = i)
  }
  Ke.core = Xe, wt.F = 1, wt.G = 2, wt.S = 4, wt.P = 8, wt.B = 16, wt.W = 32, wt.U = 64, wt.R = 128
  var bt = wt, xt = Object('z').propertyIsEnumerable(0) ? Object : function(e) {
      return 'String' == Qe(e) ? e.split('') : Object(e)
    }, Ot = function(e) {
      if (null == e) throw TypeError('Can\'t call method on  ' + e)
      return e
    }, jt = function(e) {
      return xt(Ot(e))
    }, kt = Math.ceil, St = Math.floor, Et = function(e) {
      return isNaN(e = +e) ? 0 : (e > 0 ? St : kt)(e)
    }, It = Math.min, Pt = Math.max, Tt = Math.min, Ct = Ve('keys'), Rt = function(e, t, n) {
      for (var o = jt(e), r = function(e) {
        return e > 0 ? It(Et(e), 9007199254740991) : 0
      }(o.length), i = function(e, t) {
        return (e = Et(e)) < 0 ? Pt(e + t, 0) : Tt(e, t)
      }(n, r); r > i; i++) if (i in o && o[i] === t) return i || 0
      return -1
    }, Lt = Ct.IE_PROTO || (Ct.IE_PROTO = et('IE_PROTO')),
    At = 'constructor,hasOwnProperty,isPrototypeOf,propertyIsEnumerable,toLocaleString,toString,valueOf'.split(','),
    Mt = Object.keys || function(e) {
      return function(e, t) {
        var n, o = jt(e), r = 0, i = []
        for (n in o) n != Lt && vt(o, n) && i.push(n)
        for (; t.length > r;) vt(o, n = t[r++]) && (~Rt(i, n) || i.push(n))
        return i
      }(e, At)
    }, Nt = { f: {}.propertyIsEnumerable }, Dt = Nt.f, Ut = function(e) {
      for (var t, n = jt(e), o = Mt(n), r = o.length, i = 0, s = []; r > i;) t = o[i++], at && !Dt.call(n, t) || s.push([t, n[t]])
      return s
    }
  bt(bt.S, 'Object', {
    entries: function(e) {
      return Ut(e)
    },
  })
  var Ht = function(e) {
    return e.split('/').slice(0, -1).join('/') + '/'
  }, zt = function(e) {
    return /^(?:http(?:s?)\:)?\/\//.test(e) ? e : Ht(window.location.href) + e
  }, qt = function(e) {
    var t = e.split('?'), n = t[0], o = t[1]
    return { endpoint: n, queryString: o, params: o ? Gt(o) : {} }
  }, Ft = function e(t) {
    for (var n = arguments.length, o = new Array(n > 1 ? n - 1 : 0), r = 1; r < n; r++) o[r - 1] = arguments[r]
    return o.forEach((function(n) {
      Object.entries(n).forEach((function(n) {
        var o = n[0], r = n[1]
        '__proto__' !== o && (t.hasOwnProperty(o) && null !== t[o] && 'object' == typeof t[o] && 'object' == typeof r ? t[o] = e(t[o], r) : t[o] = r)
      }))
    })), t
  }, Gt = function(e) {
    return (/\?.+/.test(e) ? e.substring(e.indexOf('?') + 1) : e).split('&').reduce((function(e, t) {
      var n = t.split('='), o = n[0], r = n[1]
      return e[o] = decodeURIComponent(r), e
    }), {})
  }, Bt = function(e, t) {
    Object.entries(t).forEach((function(t) {
      var n = t[0], o = t[1]
      e.style[n] = o
    }))
  }, Wt = function(e, t) {
    Object.entries(t).forEach((function(t) {
      var n = t[0], o = t[1]
      e.setAttribute(n, o)
    }))
  }, Zt = function(e) {
    return document.getElementById(e) || ((t = document.createElement('div')).setAttribute('id', e), t)
    var t
  }, Jt = function(e) {
    var t = e.message, n = e.code, o = e.request, r = new Error(t)
    return n && (r.code = n), r.request = o, r
  }, Qt = { oxygen: 'https://accounts-staging.autodesk.com', dhx: 'https://akn.services-staging.autodesk.com' }, Xt = {
    environment: 'production',
    endpoints: {
      dev: Qt,
      staging: Qt,
      production: { oxygen: 'https://accounts.autodesk.com', dhx: 'https://akn.services.autodesk.com' },
    },
  }, Kt = { returnUrl: '', stateLess: !1, responseType: 'qry' }, Vt = {}, Yt = function(e) {
    var t, n = e.url, o = e.params, r = void 0 === o ? {} : o
    return (t = {
      url: n,
      method: 'get',
      withCredentials: e.withCredentials,
      data: r,
      timeout: 3e4,
    }, void 0 === t && (t = {}), t.headers = Ft({
      Accept: 'application/json, text/javascript, */*; q=0.01',
      'Content-Type': 'application/json;charset=utf-8',
    }, t.headers || {}), function(e) {
      var t = e.url, n = e.method, o = void 0 === n ? 'get' : n, r = e.withCredentials, i = e.headers,
        s = void 0 === i ? {} : i, a = e.data, c = void 0 === a ? null : a
      return new Promise((function(e, n) {
        var i = new XMLHttpRequest, a = o.toUpperCase(), u = 'GET' === a ? function(e, t) {
          void 0 === t && (t = {})
          var n = Object.keys(t.params || {}).reduce((function(e, n, o, r) {
            return e.push(n + (t.params.hasOwnProperty(n) && null !== t.params[n] ? '=' + encodeURIComponent(t.params[n]) : '')), e
          }), []), o = -1 === e.indexOf('?') ? '?' : '&'
          return e + (n.length ? o + n.join('&') : '')
        }(t, { params: c }) : t
        i.open(a, u), Object.keys(s).forEach((function(e) {
          i.setRequestHeader(e, s[e])
        })), i.onreadystatechange = function() {
          i && 4 === i.readyState && (0 !== i.status || i.responseURL && 0 === i.responseURL.indexOf('file:')) && (i.status >= 200 && i.status < 300 ? e({
            data: i.responseText,
            headers: i.getAllResponseHeaders && i.getAllResponseHeaders() || null,
            status: i.status,
            statusText: i.statusText,
            request: i,
          }) : n(Jt({ message: 'Request failed with code ' + i.status + ' - ' + i.responseURL, request: i })), i = null)
        }, i.onerror = function() {
          n(Jt({ message: 'Network error.', request: i })), i = null
        }, i.ontimeout = function() {
          n(Jt({ message: 'Network timeout.', code: 'ECONNABORTED', request: i })), i = null
        }, r && (i.withCredentials = r), i.send(c)
      }))
    }(t).then((function(e) {
      return e.data = JSON.parse(e.data), e
    }))).then((function(e) {
      return e.data
    }))
  }, $t = {
    getOptions: function() {
      var e = window.Oxygen && window.Oxygen.getOptions() || {},
        t = { environment: e.environment, viewmode: e.viewmode }
      return Ft({}, Xt, t, Vt)
    }, setOptions: function(e) {
      return Ft(Vt, e), e.onOpenIDResponse && window.Oxygen && window.Oxygen.setOptions({ onOpenIDResponse: e.onOpenIDResponse }), $t
    }, checkImmediate: function(e, t, n) {
      void 0 === e && (e = Kt.returnUrl), void 0 === t && (t = Kt.stateLess), void 0 === n && (n = Kt.responseType)
      var o = Ft({}, Kt, 'object' == typeof e ? e : { returnUrl: e, stateLess: t, responseType: n }),
        r = $t.getOptions(), i = r.endpoints[r.environment].dhx, s = {
          identifier: r.endpoints[r.environment].oxygen,
          returnUrl: o.returnUrl || Ht(window.location.href),
          responseType: o.responseType,
          stateLess: o.stateLess,
        }
      return Yt({
        url: i + '/aknuserservices/user/openid/v2/checkidimmediate',
        params: s,
        withCredentials: !o.stateLess,
      }).then((function(e) {
        return 'SUCCESS' === e.MESSAGE ? !o.stateLess && e.CONSUMER_RESPONSE_DATA ? {
          namePerson: e.CONSUMER_RESPONSE_DATA.first + ' ' + e.CONSUMER_RESPONSE_DATA.last,
          'autodesk/userid': e.CONSUMER_RESPONSE_DATA.userid,
        } : e.REDIRECT_URL ? window.Oxygen ? (window.Oxygen.checkImmediate(e.REDIRECT_URL), {}) : e.REDIRECT_URL : e : {}
      }))
    }, showSignIn: function(e, t, n, o) {
      void 0 === e && (e = Kt.returnUrl), void 0 === n && (n = Kt.stateLess), void 0 === o && (o = Kt.responseType)
      var r = Ft({}, Kt, 'object' == typeof e ? e : { returnUrl: e, lang: t, stateLess: n, responseType: o }),
        i = $t.getOptions(), s = i.endpoints[i.environment].dhx, a = {
          identifier: i.endpoints[i.environment].oxygen,
          returnUrl: r.returnUrl || ('redirect' === i.viewmode ? window.location.href : Ht(window.location.href)),
          mode: 'redirect' === i.viewmode ? 'popup' : i.viewmode || 'iframe',
          responseType: r.responseType,
          stateLess: r.stateLess,
        }
      return r.lang && (a.lang = r.lang), Yt({
        url: s + '/aknuserservices/user/openid/v2/signin',
        params: a,
        withCredentials: !r.stateLess,
      }).then((function(e) {
        return e.REDIRECT_URL && window.Oxygen && window.Oxygen.show(e.REDIRECT_URL, i.viewmode), e
      }))
    }, signOut: function(e, t) {
      void 0 === e && (e = ''), void 0 === t && (t = !1)
      var n = $t.getOptions(), o = n.endpoints[n.environment].dhx
      return Yt({ url: o + '/aknuserservices/user/openid/v2/signoff', params: { callback: '' } }).then((function(o) {
        return !t && window.Oxygen && window.Oxygen.signOut(e, n.environment), o
      }))
    }, getAnalyticsId: function(e) {
      var t = $t.getOptions(), n = t.endpoints[t.environment].dhx
      return Yt({ url: n + '/aknuserservices/user/v1/analytics/analyticsid/user/' + e }).then((function(e) {
        return e.analyticsId
      })).catch((function() {
        return Promise.resolve(null)
      }))
    }, getEncodedCSN: function(e) {
      var t = $t.getOptions(), n = t.endpoints[t.environment].dhx
      return Yt({ url: n + '/aknuserservices/user/entitlement/v3/enc/csn/' + e }).then((function(e) {
        return e.enccsn
      })).catch((function() {
        return Promise.resolve(null)
      }))
    }, getUserData: function() {
      var e = $t.getOptions(), t = e.endpoints[e.environment].dhx
      return Yt({ url: t + '/aknuserservices/user/openid/v2/getuserdata', withCredentials: !0 }).catch((function() {
        return Promise.resolve({})
      }))
    }, getEntitlementData: function() {
      var e = $t.getOptions(), t = e.endpoints[e.environment].dhx
      return Yt({
        url: t + '/aknuserservices/user/entitlement/v3/app/knowledge/getentitlementdata',
        withCredentials: !0,
      }).catch((function() {
        return Promise.resolve({})
      }))
    },
  }, en = { f: Object.getOwnPropertySymbols }, tn = function(e) {
    return Object(Ot(e))
  }, nn = Object.assign, on = !nn || st((function() {
    var e = {}, t = {}, n = Symbol(), o = 'abcdefghijklmnopqrst'
    return e[n] = 7, o.split('').forEach((function(e) {
      t[e] = e
    })), 7 != nn({}, e)[n] || Object.keys(nn({}, t)).join('') != o
  })) ? function(e, t) {
    for (var n = tn(e), o = arguments.length, r = 1, i = en.f, s = Nt.f; o > r;) for (var a, c = xt(arguments[r++]), u = i ? Mt(c).concat(i(c)) : Mt(c), p = u.length, f = 0; p > f;) a = u[f++], at && !s.call(c, a) || (n[a] = c[a])
    return n
  } : nn
  bt(bt.S + bt.F, 'Object', { assign: on })
  var rn = 'http://specs.openid.net/auth/2.0', sn = function(e, t, n) {
      n && n.length && (e.push(['ax.' + t, n]), n.forEach((function(t) {
        e.push(['ax.type.' + t, 'http://axschema.org/' + t])
      })))
    }, an = function(e) {
      var t, n, o = function(e) {
        var t = [['ns', rn], ['claimed_id', rn + '/identifier_select'], ['identity', rn + '/identifier_select'], ['return_to', e.openid_response || window.location.href], ['realm', e.openid_response || Ht(window.location.href)]]
        switch (e.mode) {
          case'register':
            t.push(['ns.alias4', 'http://autodesk.com/openid/ext/register/1.0'], ['alias4.mode', 'register'])
          case'checkid_setup':
            t.push(['mode', 'checkid_setup'], ['ns.alias5', 'http://specs.openid.net/extensions/ui/1.0'], ['alias5.mode', e.viewmode]), e.refresh_response && t.push(['alias5.iframe_refresh_url', e.refresh_response])
            break
          case'checkid_immediate':
          default:
            t.push(['mode', e.mode])
        }
        return cn(t)
      }({
        mode: e.mode,
        viewmode: e.viewmode,
        openid_response: e.openid_response && zt(e.openid_response),
        refresh_response: e.refresh_response && zt(e.refresh_response),
      }), r = e.ax_params && (n = [], (t = {
        required: e.ax_params.required,
        if_available: e.ax_params.if_available,
      }) && (n.push(['ns.ax', 'http://openid.net/srv/ax/1.0'], ['ax.mode', 'fetch_request']), sn(n, 'required', t.required), sn(n, 'if_available', t.if_available)), cn(n)) || []
      return un(e.endpoint, o.concat(r))
    }, cn = function(e) {
      return e.map((function(e) {
        return ['openid.' + e[0], e[1]]
      }))
    }, un = function(e, t) {
      var n
      return e + '?' + (n = t, Array.isArray(n) ? n : Object.entries(n) || []).map((function(e) {
        var t = e[0], n = e[1]
        return t + '=' + encodeURIComponent(n)
      })).join('&')
    }, pn = { border: '0 none', outline: '0 none' }, fn = { frameborder: '0', allowtransparency: 'true' },
    dn = function(e) {
      void 0 === e && (e = {})
      var t = document.createElement('iframe')
      return t.addEventListener('load', e.onload || function() {
      }, !1), Bt(t, Ft({}, pn, e.styles || {})), Wt(t, Ft({}, fn, e.attrs || {}, { src: e.src })), t
    }, ln = function(e) {
      var t, n

      function o(t) {
        return void 0 === t && (t = {}), e.call(this, t) || this
      }

      n = e, (t = o).prototype = Object.create(n.prototype), t.prototype.constructor = t, t.__proto__ = n
      var r = o.prototype
      return r.open = function(t, n) {
        this.iframe ? t && this.iframe.setAttribute('src', t) : this.iframe = dn({
          src: t,
          onload: this.options.onLoadComplete,
          styles: { display: 'block', width: '100%', height: '100%', minWidth: '0', minHeight: '0' },
        }), this.iframe.parentNode || (this.empty(),
          this.container.appendChild(this.iframe)), e.prototype.open.call(this, void 0, n)
      }, r.close = function() {
        var t = this
        e.prototype.close.call(this, (function() {
          t.iframe && t.iframe.parentNode && t.iframe.parentNode.removeChild(t.iframe), t.iframe = null
        }))
      }, o
    }(function() {
      function e(e) {
        void 0 === e && (e = {}), this.overlay = Zt('oxygen-overlay'), this.container = Zt('oxygen-container'), this.close_btn = Zt('oxygen-closebtn'), this.options = Ft({}, {
          zIndex: 6e4,
          onCreate: function() {
          },
          onOpen: function() {
          },
          onClose: function() {
          },
          styles: {
            overlay: { position: 'fixed', left: 0, top: 0, right: 0, bottom: 0, transition: 'opacity .33s ease' },
            container: {
              position: 'fixed',
              maxHeight: 'calc(100vh - 42px)',
              maxWidth: 'calc(100vw - 42px)',
              top: '50%',
              left: '50%',
              webkitTransform: 'translate(-50%, -50%)',
              msTransform: 'translate(-50%, -50%)',
              transform: 'translate(-50%, -50%)',
              transition: 'opacity .33s ease, width .33s ease, height .33s ease',
              outline: '0 none',
            },
          },
        }, e), Bt(this.overlay, Ft({
          zIndex: this.options.zIndex,
          opacity: 0,
        }, this.options.styles.overlay)), Bt(this.container, Ft({
          width: this.options.width || 'auto',
          height: this.options.height || 'auto',
          zIndex: this.options.zIndex + 6,
          opacity: 0,
        }, this.options.styles.container)), Wt(this.container, {
          role: 'dialog',
          tabindex: '-1',
        }), Wt(this.close_btn, { role: 'button' }), this.__eventsAttached = !1, this.__keyDownHandler = function(e) {
          27 === e.keyCode && this.close()
        }.bind(this), this.__overlayClickHandler = function(e) {
          e.stopPropagation(), this.options.hideOnOverlayClick && this.close()
        }.bind(this), this.__mouseWheelHandler = function(e) {
          e.stopPropagation(), e.preventDefault()
        }, this.options.styles.close_button && (Bt(this.close_btn, this.options.styles.close_button), this.close_btn.addEventListener('click', this.close.bind(this), !1), this.container.appendChild(this.close_btn)), this.options.content && (this.empty(), this.container.appendChild(this.options.content))
      }

      var t = e.prototype
      return t.empty = function() {
        for (var e = this.container.children.length; e; e--) this.container.children[e - 1] !== this.close_btn && this.container.removeChild(this.container.children[e - 1])
      }, t._show = function() {
        var e = this
        Bt(this.overlay, { display: 'block' }), Bt(this.container, { display: 'block' }), setTimeout((function() {
          Bt(e.overlay, { opacity: e.options.styles.overlay.opacity }), Bt(e.container, { opacity: 1 })
        }), 25)
      }, t._hide = function() {
        var e = this
        Bt(this.overlay, { opacity: 0 }), Bt(this.container, { opacity: 0 }), setTimeout((function() {
          Bt(e.overlay, { display: 'none' }), Bt(e.container, { display: 'none' })
        }), 333)
      }, t.open = function(e, t) {
        var n = this
        this.__eventsAttached || (document.body.addEventListener('keydown', this.__keyDownHandler, !1), this.overlay.addEventListener('click', this.__overlayClickHandler, !1), this.overlay.addEventListener('mousewheel', this.__mouseWheelHandler, !1), this.container.addEventListener('mousewheel', this.__mouseWheelHandler, !1), this.__eventsAttached = !0), e && (this.options.content = e, this.empty(), this.container.appendChild(this.options.content)), this.overlay.parentNode || document.body.appendChild(this.overlay), this.container.parentNode || document.body.appendChild(this.container), this.resizeTo(t || this.options)
        try {
          this.container.focus()
        } catch (e) {
        }
        this.options.onCreate && this.options.onCreate(), this._show(), setTimeout((function() {
          n.options.onOpen && n.options.onOpen()
        }), 333)
      }, t.close = function(e) {
        var t = this
        document.body.removeEventListener('keydown', this.__keyDownHandler), this.overlay.removeEventListener('click', this.__overlayClickHandler), this._hide(), this.overlay.removeEventListener('mousewheel', this.__mouseWheelHandler), this.container.removeEventListener('mousewheel', this.__mouseWheelHandler), this.__eventsAttached = !1, setTimeout((function() {
          t.options.onClose && t.options.onClose(), e && e()
        }), 333)
      }, t.resizeTo = function(e) {
        e && (this.options.width = e.width, this.options.height = e.height)
        var t = e.width || this.options.width, n = e.height || this.options.height;
        -1 === t.indexOf('px') && (t += 'px'), -1 === n.indexOf('px') && (n += 'px'), Bt(this.container, {
          width: t,
          height: n,
        })
      }, e
    }()), hn = function() {
      function e(e) {
        void 0 === e && (e = {}), this.popup = null, this.options = Ft({}, { width: 350, height: 420 }, e)
      }

      var t = e.prototype
      return t.open = function(e) {
        try {
          this.popup.location.href = e
        } catch (t) {
          this.popup = window.open(e, 'OxygenPopup', 'width=' + this.options.width + ',height=' + this.options.height), this.popup && this.popup.addEventListener('load', this.options.onLoadComplete || function() {
          }, !1)
        }
      }, t.close = function() {
        this.popup.close(), this.popup = null
      }, t.resizeTo = function(e) {
        try {
          popup && popup.resizeTo(e.width, e.height)
        } catch (e) {
        }
      }, e
    }(), vn = function() {
      function e() {
      }

      var t = e.prototype
      return t.open = function(e) {
        window.top.location.href = e
      }, t.close = function() {
      }, t.resizeTo = function() {
      }, e
    }(), mn = {
      base: {
        overlay: { backgroundColor: '#000' },
        container: { backgroundColor: '#fff' },
        close_button: {
          backgroundPosition: '50% 50%',
          backgroundRepeat: 'no-repeat',
          backgroundImage: 'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiI+PHBhdGggc3Ryb2tlPSIjNDQ1IiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik0yIDJsMTIgMTJNMiAxNEwxNCAyIi8+PC9zdmc+")',
          width: '16px',
          height: '16px',
          position: 'absolute',
          top: '0',
          right: '0',
          cursor: 'pointer',
        },
      },
      legacy: {
        extend: 'base',
        overlay: { opacity: .42 },
        container: { boxShadow: '0 2px 8px rgba(0,0,0,0.5)', padding: '10px' },
        close_button: {
          backgroundImage: 'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiI+PHBhdGggc3Ryb2tlPSIjY2NjIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik0zLjUgMy41bDkgOW0tOSAwbDktOSIvPjwvc3ZnPg==")',
          backgroundColor: '#3e3e3e',
          top: '-8px',
          right: '-8px',
          border: '2px solid #ccc',
          borderRadius: '50%',
          boxShadow: '0 1px 3px rgba(0,0,0,0.82)',
        },
      },
      hig: {
        extend: 'base',
        overlay: { opacity: .4 },
        container: {
          boxShadow: '0 0 8px rgba(0,0,0,0.4)',
          padding: '20px 10px 10px',
          borderRadius: '4px',
          border: '3px solid #fff',
        },
        close_button: { top: '2px', right: '2px' },
      },
      akn: {
        extend: 'base',
        overlay: { opacity: .6 },
        container: { boxShadow: 'inset 0 26px #f3f3f3', padding: '26px 10px 10px', border: '2px solid #888' },
        close_button: {
          top: '4px',
          right: '4px',
          backgroundColor: '#fff',
          backgroundSize: '75%',
          border: '1px solid #ccc',
        },
      },
    }, yn = null, gn = {
      viewmode: 'iframe',
      viewmode_options: { width: '350px', height: '420px', theme: 'hig', hideOnOverlayClick: !1 },
      environment: 'production',
      providers: {
        production: 'https://accounts.autodesk.com',
        staging: 'https://accounts-staging.autodesk.com',
        dev: 'https://accounts-dev.autodesk.com',
      },
      openid_response: '',
      refresh_response: '',
      ax_params: { required: ['namePerson', 'autodesk/userid'], if_available: ['autodesk/media/image/50'] },
    }, _n = {}, wn = function e(t) {
      return t.extend ? Ft({}, e(mn[t.extend]), t) : t
    }, bn = function(e, t, n) {
      return {
        mode: n,
        viewmode: e.viewmode,
        openid_response: e.openid_response,
        refresh_response: e.refresh_response,
        ax_params: e.ax_params,
        endpoint: e.providers[e.environment] + t,
      }
    }
    if(window.addEventListener) {
    window.addEventListener('message', (function(e) {
    if (/(\.autodesk\.com)$/.test(e.origin)) {
      var t = /^iframe\-resize::(\d+),(\d+)/
      if ('string' == typeof e.data && t.test(e.data)) {
        var n = e.data.match(t) || [], o = n[1], r = n[2];
        (o || r) && On.resizeTo({ width: o, height: r })
      }
    }
  }), !1)}

  var xn = function(e) {
    return dn({
      src: e, onload: function(e) {
        var t = this
        setTimeout((function() {
          var n;
          (n = e.target || t).parentNode && n.parentNode.removeChild(n)
        }), 3e3)
      }, styles: { display: 'none' },
    })
  }, On = {
    show: function(e, t, n) {
      t && t !== gn.viewmode && (yn = null, gn.viewmode = t)
      var o, r, i = e && -1 !== e.indexOf('.') ? e : an(bn(gn, '/SignIn', e || 'checkid_setup'))
      yn || (yn = function(e, t) {
        switch (e) {
          case'popup':
            return new hn(t)
          case'redirect':
            return new vn(t)
          case'iframe':
          default:
            return new ln(t)
        }
      }(gn.viewmode, (r = function(e) {
        switch (typeof e) {
          case'string':
            return mn[e]
          case'function':
            return e.call(null, mn)
          default:
            return e
        }
      }((o = gn.viewmode_options).theme), Object.assign({}, o, { styles: wn(r) })))), gn.viewmode_options.onLoadStart && gn.viewmode_options.onLoadStart(), yn.open(i, n)
    }, hide: function() {
      yn && yn.close()
    }, resizeTo: function(e) {
      yn && yn.resizeTo(e)
    }, getOptions: function() {
      return gn
    }, setOptions: function(e) {
      return yn && e.hasOwnProperty('viewmode') && e.viewmode !== gn.viewmode && (yn.close(), yn = null), Ft(gn, e), On
    }, checkImmediate: function(e) {
      var t = e || an(bn(gn, '/SignIn', 'checkid_immediate')), n = xn(t)
      document.body.appendChild(n)
    }, showSignIn: function(e, t, n) {
      On.show(e, t, n)
    }, signOut: function(e, t) {
      var n = gn.providers[t || gn.environment] + '/Authentication/LogOut'
      e && (n += '?ReturnToUrl=' + encodeURIComponent(zt(e)))
      var o = xn(n)
      document.body.appendChild(o)
    }, verifyAssertion: function(e, t) {
      var n = qt(e)
      if (!n.params['openid.return_to']) return !1
      var o = qt(t), r = qt(n.params['openid.return_to'])
      if (o.endpoint !== r.endpoint) return !1
      if (Object.entries(r.params).some((function(e) {
        var t = e[0], n = e[1]
        return o.params[t] !== n
      }))) return !1
      var i = n.params['openid.response_nonce']
      if (!i) return !1
      if (_n.hasOwnProperty(i)) return !1
      _n[i] = !0
      var s = n.params['openid.sig'], a = (n.params['openid.signed'] || '').split(',')
      return !(!s || !a.length || a.some((function(e) {
        return !n.params.hasOwnProperty('openid.' + e)
      })))
    }, processOpenIDResponse: function(e) {
      if ('string' == typeof e) {
        var t = (n = qt(e).params, o = Object.entries(n).reduce((function(e, t) {
          var n = t[0], o = t[1], r = (0 === n.indexOf('openid.') ? n.split('.') : [null, n]).slice(1),
            i = r.length - 1
          return r.reduce((function(e, t, n) {
            return n < i ? 'string' == typeof e[t] ? e[t] = { _value: e[t] } : e[t] = e[t] || {} : e[t] = o, e[t]
          }), e), e
        }), {}), i = (r = o.ns && Object.entries(o.ns).reduce((function(e, t) {
          var n = t[0]
          return 'http://openid.net/srv/ax/1.0' === t[1] ? n : e
        }), '') || '') && Object.entries(o[r].type).map((function(e) {
          var t = e[0]
          return [e[1].replace('http://axschema.org/', ''), o[r].value[t]]
        })).reduce((function(e, t) {
          var n = t[0], o = t[1]
          return e[n] = o, e
        }), {}) || {}, { params: o, axData: i })
        gn.onOpenIDResponse && gn.onOpenIDResponse(t.params, t.axData, e)
      } else gn.onOpenIDResponse && gn.onOpenIDResponse(e)
      var n, o, r, i
      yn && yn.close()
    }, processOpenIDRefreshResponse: function(e) {
      var t = 'string' == typeof e ? qt(e).params : e
      t.page ? On.show(t.page, void 0, { width: t.width, height: t.height }) : On.resizeTo({
        width: t.width,
        height: t.height,
      })
    }, toString: function() {
      return 'Oxygen'
    },
  }
  return window.Oxygen = window.Oxygen || On, $t
}))