import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import produce from 'immer';
import deepmerge from 'deepmerge';
import window from 'global/window';
import DHXOxygen from '../lib/DHXOxygen';
import logger from '../app/common/logger';
import {
  LL_PRODUCER_API,
  OXYGEN_RESPONSE_URL,
  LL_CONSUMER_API,
  LRS_VERB_COMPLETED,
  LRS_VERB_STARTED,
  STAGE_ENV,
  PROD_ENV,
  PERF_ENV,
  LL_RECORD_FILTER,
  LMS_ID_API,
  LOCAL_STORAGE_LRS_KEY,
  RETRY_COUNT,
} from '../app/common/constants';

import {
  chunkArray, generateURN, generateLRSContext, getEnvironmentConstant, isLearningRecordForCourse, oneWayHash,
} from '../app/common/utilities';
import { getOutlineState } from './universalDataFetchers';
import XHRError from '../app/common/xhrError';
import { setChildProperty } from '../app/common/object';

axiosRetry(axios, { retries: RETRY_COUNT });

export const ssoInstance = window.adsk?.oxygen?.ssoFactory ? window.adsk.oxygen.ssoFactory({
  mode: 'DOTCOM',
  locale: document.documentElement.lang,
  seamlessLoginCrossSites: true,
  autoInit: false,
}) : false;

export const fetchLMSID = createAsyncThunk(
  'lmsData',
  async ({ env, uid }) => new Promise((resolve, reject) => {
    axios.get(`${getEnvironmentConstant({ env, key: LMS_ID_API })}${uid}`)
      .then((res) => {
        const results = res.data?.results;
        if (Array.isArray(results) && results.length) {
          resolve(results[0].username);
        }
        reject(new Error('No results'));
      })
      .catch((err) => {
        logger.error('Error in fetch LMSID: ', err);
        reject(err);
      });
  }),
);

export const fetchUserData = createAsyncThunk(
  'userData',
  async (env) => new Promise((resolve, reject) => {
    let OxygenEnv;
    switch (env) {
      case PROD_ENV:
        OxygenEnv = 'production';
        break;
      case PERF_ENV:
        OxygenEnv = 'staging';
        break;
      case STAGE_ENV:
        OxygenEnv = 'staging';
        break;
      default:
        OxygenEnv = 'dev';
    }
    logger.debug('Oxygen Environment: ', OxygenEnv);
    if (typeof DHXOxygen.setOptions === 'function') {
      let analyticsId;
      DHXOxygen.setOptions({
        environment: OxygenEnv,
        viewmode: 'redirect',
        async onOpenIDResponse(params) {
          // code to handle the openid indirect responses
          switch (params.loginNeeded) {
            case 'false':
              // user is logged into Oxygen, send the session
              // data somewhere else on your app
              // analyticsId = await DHXOxygen.getAnalyticsId(params.userid);
              resolve({ ...params, analyticsId });
              break;
            case 'true':
            default:
              // user is logged out, put code here to display
              // the "Sign In" messaging on your UI
              reject(params);
              break;
          }
        },
      });
    }
    DHXOxygen.checkImmediate(OXYGEN_RESPONSE_URL);
  })
  ,
);

export const fetchLearningRecords = createAsyncThunk(
  'FetchLearningRecords',
  async ({
    uid, recordList = [], env, typeList = [],
  }, { rejectWithValue }) => {
    const postURL = getEnvironmentConstant({ env, key: LL_CONSUMER_API });

    const recordChunks = chunkArray(recordList, 25);
    // If no chunks requested push an empty array into the chunks
    if (!recordChunks.length) {
      recordChunks.push([]);
    }

    const axiosCalls = recordChunks.map((chunkList) => axios
      .post(postURL, {
        userid: uid,
        objectIds: chunkList,
        objectTypes: typeList,
        verbTypes: [
          LRS_VERB_COMPLETED,
          LRS_VERB_STARTED,
        ],
        latestOnly: true,
      })
      .then((res) => res.data));

    return Promise.all(axiosCalls)
      .then((resultObjects) => {
        const data = Object.assign(...resultObjects);

        // Filter Data to remove records from other environments
        Object.keys(data).forEach((key) => {
          if (!key.startsWith(getEnvironmentConstant({ env, key: LL_RECORD_FILTER }))) {
            delete data[key];
          }
        });

        return data;
      }).catch((err) => {
        const xhrErr = new XHRError(err?.response?.status || 500, err.message);
        return rejectWithValue(xhrErr);
      });
  },
);

export const fetchCourseData = createAsyncThunk(
  'FetchCourseData',
  async ({
    uid, env, typeList = [], learningRecords, lmsHeaders = {},
  }, { rejectWithValue }) => {
    const postURL = getEnvironmentConstant({ env, key: LL_CONSUMER_API });

    const axiosCalls = [axios
      .post(postURL, {
        userid: uid,
        objectTypes: typeList,
        verbTypes: [
          LRS_VERB_COMPLETED,
          LRS_VERB_STARTED,
        ],
        latestOnly: true,
      })
      .catch((err) => {
        logger.error('Failed getting learning records for fetchCourseData: ', err);
        return { data: null };
      })
      .then((res) => res.data),
    ];

    const courseIds = [];
    Object.keys(learningRecords).forEach((recId) => {
      if (isLearningRecordForCourse(recId)) {
        // Only get outline state for course records
        const courseSplit = recId.split('/courses/');
        if (courseSplit.length > 1) {
          const course = encodeURIComponent(courseSplit[1].replace(/\//g, ''));
          axiosCalls.push(getOutlineState({ course, env, lmsHeaders }).catch((err) => {
            logger.error(`Failed getting course data for ${course} in fetchCourseData: `, err);
            return null;
          }));
          courseIds.push(recId);
        }
      }
    });

    return Promise.all(axiosCalls).then((resultObjects) => {
      const learningRecs = resultObjects.shift();
      const courseData = {};
      courseIds.forEach((id, idx) => {
        courseData[id] = resultObjects[idx];
      });
      return { learningRecs, courseData };
    }).catch((err) => {
      const xhrErr = new XHRError(err?.response?.status || 500, err.message);
      return rejectWithValue(xhrErr);
    });
  },
);

export const sendUserData = createAsyncThunk(
  'SendUserData',
  async ({
    uid, verb, block, env, blocks = {},
  }) => {
    const postURL = getEnvironmentConstant({ env, key: LL_PRODUCER_API });

    const URN = generateURN({ uid, verb, block });
    const context = generateLRSContext({
      blocks, block,
    });
    return axios
      .post(postURL, { event: URN, context })
      .catch((err) => {
        logger.error(`Failed in SendUserData - URN: ${URN} posting to ${postURL}`, err);
      }) // TODO: Handle error state
      .then((res) => res.json);
  },
);

const updateLearningRecords = ({ learningRecords, payload, id }) => {
  let data;
  let allSavedRecords = '{}';
  try {
    allSavedRecords = localStorage.getItem(LOCAL_STORAGE_LRS_KEY) || '{}';
    const savedRecords = JSON.parse(allSavedRecords)[oneWayHash(id)] || {};
    data = deepmerge(savedRecords, payload);
  } catch (e) {
    logger.error('Failed parsing local storage', e);
    data = payload;
  }

  const returnedRecords = produce(learningRecords, (draftState) => {
    Object.keys(data).forEach((itemId) => {
      const record = data[itemId];

      if (learningRecords[itemId]) {
        // Merge statements with existing record
        record.statements.forEach((statement) => {
          const { verb } = statement;
          const matchingStatement = draftState[itemId].statements.map((s) => s.verb === verb);
          if (matchingStatement.length) {
            // Statement for this verb exists so compare timestamps
            if (new Date(matchingStatement[0].timestamp) < new Date(statement.timestamp)) {
              // New timestamp is greater so update
              matchingStatement[0] = { ...matchingStatement[0], timestamp: statement.timestamp };
            }
          } else {
            // Statement for this verb doesn't exist yet so push it
            draftState[itemId].statements.push(statement);
          }
        });
      } else {
        // Record for this activity does not exist yet so add it.
        draftState[itemId] = record;
      }
    });
  });

  try {
    const curData = {};
    const idKey = oneWayHash(id);
    curData[idKey] = returnedRecords;
    const parsedSaved = JSON.parse(allSavedRecords);
    const mergedData = deepmerge(parsedSaved, curData);
    // Dedupe statements
    Object.keys(mergedData[idKey]).forEach((storeKey) => {
      mergedData[idKey][storeKey].statements = mergedData[idKey][storeKey].statements.filter(
        (v, i, a) => a.findIndex(
          (t) => t.verb === v.verb,
        ) === i,
      );
    });
    localStorage.setItem(LOCAL_STORAGE_LRS_KEY, JSON.stringify(mergedData));
  } catch (e) {
    logger.error('Failed setting local storage', e);
  }

  return returnedRecords;
};

export const userSlice = createSlice({
  name: 'userData',
  initialState: {
    userDetails: {},
    learningRecords: {},
    courseData: {},
    dashboardState: {},
    selectedSort: 'alpha',
    isLoaderVisible: false,
    fetchingLearningRecords: false,
    fetchingCourseData: false,
    hasFetchedLearningRecords: false,
    authChecked: false,
    lastRecordSetDate: new Date().toUTCString(),
  },
  reducers: {
    toggleDashboardDrawerState: (state, action) => {
      const newState = produce(state, (draftState) => {
        draftState.dashboardState[action?.payload] = draftState.dashboardState[action?.payload] !== true;
      });
      return newState;
    },
    setSelectedSort: (state, action) => ({
      ...state,
      selectedSort: action?.payload || 'alpha',
    }),
    setUserDetails: (state, action) => {
      const newState = produce(state, (draftState) => {
        draftState.userDetails = action?.payload || {};
      });
      return newState;
    },
    setLearningRecords: (state, action) => {
      const newState = produce(state, (draftState) => {
        draftState.learningRecords = updateLearningRecords({
          learningRecords: state?.learningRecords,
          payload: action?.payload?.learningRecords || [],
          id: draftState.userDetails.userid,
        });
        draftState.lastRecordSetDate = new Date().toUTCString();
      });
      return newState;
    },
    clearLearningRecords: (state) => {
      const newState = produce(state, (draftState) => {
        draftState.learningRecords = {};
        draftState.cleared = true;
        draftState.hasFetchedLearningRecords = false;
      });

      return newState;
    },
  },
  extraReducers: {

    [sendUserData.pending]: (state) => ({
      ...state,
      sendingToProducer: true,
    }),
    [sendUserData.fulfilled]: (state) => {
      // User data has been returned.
      const newState = {
        sendingToProducer: false,
      };

      return {
        ...state,
        ...newState,
      };
    },
    [sendUserData.rejected]: (state) => {
      // User data has been returned.
      const newState = {
        sendingToProducer: false,
      };

      return {
        ...state,
        ...newState,
      };
    },
    [fetchUserData.pending]: (state) => ({
      ...state,
      isLoaderVisible: true,
      authChecked: false,
    }),
    [fetchUserData.fulfilled]: (state, action) => {
      logger.debug('fetchUserData.fulfilled');
      // User data has been returned.
      const newState = {
        isLoaderVisible: false,
        authChecked: true,
      };

      newState.userDetails = action?.payload || {};
      return {
        ...state,
        ...newState,
      };
    },
    [fetchUserData.rejected]: (state, err) => {
      // User is not logged in. Ensure removal of user-data from store.
      logger.info('fetchUserData.rejected - User was not logged in', err);
      return {
        ...state,
        userDetails: {},
        isLoaderVisible: false,
        authChecked: true,

      };
    },
    [fetchLMSID.pending]: (state) => ({
      ...state,
      isFetchingLMSID: true,
    }),
    [fetchLMSID.fulfilled]: (state, action) => {
      logger.debug('fetchLMSID.fulfilled');
      const newState = {
        isFetchingLMSID: false,
      };

      setChildProperty(action?.payload, 'lmsid', newState);

      return {
        ...state,
        ...newState,
      };
    },
    [fetchLMSID.rejected]: (state, err) => {
      logger.error('fetchLMSID.rejected', err);
      return {
        ...state,
        isFetchingLMSID: false,
      };
    },
    [fetchLearningRecords.pending]: (state) => ({
      ...state,
      hasFetchedLearningRecords: false,
      fetchingLearningRecords: true,
    }),
    [fetchLearningRecords.fulfilled]: (state, action) => {
      // User data has been returned.
      logger.debug('fetchLearningRecords.fulfilled');
      const newState = produce(state, (draftState) => {
        draftState.cleared = false;
        draftState.fetchingLearningRecords = false;
        draftState.hasFetchedLearningRecords = true;
        draftState.lastRecordSetDate = new Date().toUTCString();
        draftState.learningRecords = updateLearningRecords({
          learningRecords: state?.learningRecords,
          payload: action?.payload || [],
          id: draftState.userDetails.userid,
        });
      });
      return newState;
    },
    [fetchLearningRecords.rejected]: (state, ...params) => {
      // User is not logged in. Ensure removal of user-data from store.
      logger.error('Fetching Learning Records was rejected: ', params);
      return {
        ...state,
        fetchingLearningRecords: false,
        hasFetchedLearningRecords: true,
      };
    },
    [fetchCourseData.pending]: (state) => ({
      ...state,
      fetchingCourseData: true,
    }),
    [fetchCourseData.fulfilled]: (state, action) => {
      // User data has been returned.
      logger.debug('fetchCourseData.fulfilled');
      const newState = produce(state, (draftState) => {
        const { learningRecs, courseData } = action?.payload;
        draftState.fetchingCourseData = false;
        if (learningRecs !== null) {
          draftState.learningRecords = updateLearningRecords({
            learningRecords: state?.learningRecords,
            payload: learningRecs || [],
            id: draftState.userDetails.userid,
          });
        }
        draftState.courseData = courseData;
      });
      return newState;
    },
    [fetchCourseData.rejected]: (state, ...params) => {
      // User is not logged in. Ensure removal of user-data from store.
      logger.error('Fetching Course Data was rejected: ', params);
      return {
        ...state,
        fetchingCourseData: false,
      };
    },
  },
});
export const {
  setUserDetails, setLearningRecords, clearLearningRecords, setSelectedSort, toggleDashboardDrawerState,
} = userSlice.actions;
export const isEnrolled = (state, { blockIds }) => {
  const hasCourseStarted = Object.keys(state.userData?.learningRecords)
    .filter(
      (recordId) => {
        if (blockIds.includes(recordId)
          && state.userData?.learningRecords[recordId].statements.filter(
            (statement) => statement.verb === LRS_VERB_STARTED,
          ).length > 0
        ) {
          return true;
        }
        return false;
      },
    ).length > 0;

  const hasChildRecordComplete = Object.keys(state.userData?.learningRecords)
    .filter(
      (recordId) => {
        if (blockIds.includes(recordId)
          && state.userData?.learningRecords[recordId].statements.filter(
            (statement) => statement.verb === LRS_VERB_COMPLETED,
          ).length > 0
        ) {
          return true;
        }
        return false;
      },
    ).length > 0;

  return hasCourseStarted || hasChildRecordComplete;
};
export const fetchingLearningRecords = (state) => state.userData.fetchingLearningRecords;
export const fetchingCourseData = (state) => state.userData.fetchingCourseData;
export const hasFetchedLearningRecords = (state) => state.userData.hasFetchedLearningRecords;
export const getUserDetails = (state) => state.userData.userDetails;
export const getUserId = (state) => state.userData.userDetails.userid;
export const getLMSId = (state) => state.userData.lmsid;
export const getLearningRecords = (state) => state.userData?.learningRecords;
export const getCourseData = (state) => state.userData?.courseData;
export const getLearningRecordById = (state, id) => state.userData?.learningRecords[id];
export const isLoaderVisible = (state) => state.userData.isLoaderVisible;
export const isRecordsCleared = (state) => state.userData.cleared;
export const lastRecordSetDate = (state) => state.userData.lastRecordSetDate;
export const isAuthChecked = (state) => state.userData.authChecked;
export const getSelectedSort = (state) => state.userData.selectedSort;
export const getdashboardState = (state) => state.userData.dashboardState;
export const isUserUnverified = (state) => {
  if (ssoInstance) {
    if (ssoInstance.isSignedIn() && !state.userData?.userDetails?.userId) {
      return true;
    }
    return false;
  }
  return null;
};
export default userSlice.reducer;
