// eslint-disable-next-line max-classes-per-file
import { setErrorState } from '../../store/errorSlice';
import { ERROR_TYPE_IFRAME } from './constants';
import logger from './logger';

const EDX_API_TIMER_MS = 30000;
const EDX_BLOCK_FIRST_TIMER_MS = 30000;
const EDX_BLOCK_SECOND_TIMER_MS = 120000;

class Timer {
  constructor() {
    this.timer = null;
  }

  set(callback, ms) {
    this.timer = setTimeout(callback, ms);
  }

  clear() {
    clearTimeout(this.timer);
  }
}

const edxApiTimerCallback = (apiURL) => {
  logger.error(`${apiURL} failed to respond in time.`);
};

export class EdXApiTimer {
  constructor() {
    this.localTimer = new Timer();
  }

  set(apiURL) {
    this.localTimer.set(() => edxApiTimerCallback(apiURL), EDX_API_TIMER_MS);
  }

  clear() {
    this.localTimer.clear();
  }
}

const edxBlockTimerFirstCallback = ({ blockUrl, lastPostedUrl }) => {
  logger.error(`${blockUrl} failed to load by first time interval. Last posted url was: ${[...lastPostedUrl.current].pop() || 'not found'}`);
};

const edxBlockTimerSecondCallback = ({ blockUrl, dispatch, lastPostedUrl }) => {
  dispatch(setErrorState(ERROR_TYPE_IFRAME));
  logger.error(`${blockUrl} failed to load by second time interval. Last posted url was: ${[...lastPostedUrl.current].pop() || 'not found'}`);
};

export class EdXBlockTimer {
  constructor({ dispatch, lastPostedUrl }) {
    this.dispatch = dispatch;
    this.lastPostedUrl = lastPostedUrl;
    this.firstTimer = new Timer();
    this.secondTimer = new Timer();
  }

  set(blockUrl) {
    this.firstTimer.set(() => edxBlockTimerFirstCallback({ blockUrl, lastPostedUrl: this.lastPostedUrl }), EDX_BLOCK_FIRST_TIMER_MS);
    this.secondTimer.set(() => edxBlockTimerSecondCallback({ blockUrl, dispatch: this.dispatch, lastPostedUrl: this.lastPostedUrl }), EDX_BLOCK_SECOND_TIMER_MS);
  }

  clear() {
    this.firstTimer.clear();
    this.secondTimer.clear();
  }
}
