// ###Method - hasChildProperty( obj, propPath )
// Public method to check if object contains a particular property at a give path.
//
// > parameters
// >
// + *obj* - *Object* Object that will looked for a particular property
// + *propPath* - *String* Property path to look
//
// > returns
// >
// + *boolean* - true/false depending on outcome of analyzing object
//
export const hasChildProperty = (object, pPath) => {
  let propPath = pPath;
  let obj = object;
  let i = 0;
  let part;

  if (!obj || typeof obj !== 'object' || !propPath || typeof propPath !== 'string') {
    return false;
  }

  // strip a leading dot
  propPath = propPath.replace(/^\./, '');
  const path = propPath.split('.');
  const { length } = path;

  for (; i < length; i += 1) {
    part = path[i];
    if (!obj || !Object.prototype.hasOwnProperty.call(obj, part)) {
      return false;
    }
    obj = obj[part];
  }
  return true;
};

// ###Method - getChildProperty( obj, propPath )
// Public method to get deep nested property value when property path is string.
//
// > parameters
// >
// + *obj* - *Object* Object that will looked for a particular property
// + *propPath* - *String* Property path to look
//
// > returns
// >
// + *value* - will return whatever is the value of the property
//
export const getChildProperty = (object, pPath) => {
  let propPath = pPath;
  let obj = object;
  let i = 0;
  let part;

  if (!obj || typeof obj !== 'object' || !propPath || typeof propPath !== 'string') {
    // don't know what to return here because property can have any type of value undefined, false, etc...
    // may be we should just throw an error. or some random string to indicate an error
    return undefined;
  }

  // convert indexes to properties for example [0] will become .0
  propPath = propPath.replace(/\[(\w+)\]/g, '.$1');
  // strip a leading dot
  propPath = propPath.replace(/^\./, '');
  const path = propPath.split('.');
  const { length } = path;

  for (; i < length; i += 1) {
    part = path[i];
    if (typeof obj === 'object' && part in obj) {
      obj = obj[part];
    } else {
      return undefined;
    }
  }
  return obj;
};

// ###Method - setChildProperty( obj, propPath )
// Public method to get deep nested property value when property path is string.
//
// > parameters
// >
// + *value* - *Any data type* Value of the child property that you want to set
// + *propPath* - *String* Property path to look
// + *obj* - *Object | Optional* Object on which child property will be set.
// + If nothing is passed an empty will be created before setting child property
//
// > returns
// >
// + *obj* - will return obj
//
export const setChildProperty = (value, pPath, object = {}) => {
  let obj = object;
  let propPath = pPath;
  let i = 0;
  let part;

  if (!propPath || typeof propPath !== 'string') {
    // don't know what to return here because property can have any type of value undefined, false, etc...
    // may be we should just throw an error. or some random string to indicate an error
    return undefined;
  }

  // convert indexes to properties for example [0] will become .0
  // propPath = propPath.replace( /\[(\w+)\]/g, '.$1' );
  // strip a leading dot
  propPath = propPath.replace(/^\./, '');
  const path = propPath.split('.');
  const length = path.length - 1;

  for (; i < length; i += 1) {
    part = path[i];
    if (!(part in obj)) {
      obj[part] = {};
    }
    obj = obj[part];
  }

  obj[path[length]] = value;
  return object;
};
