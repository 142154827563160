import window from 'global';
import { LOCAL_STORAGE_LRS_KEY, OXYGEN_REDIRECT_URL_KEY, OXYGEN_RESPONSE_URL } from './constants';
import DHXOxygen from '../../lib/DHXOxygen';

export const setLoginRedirects = (redirectUrl) => {
  // Set current URL in session storage to be redirected to after login.
  window.sessionStorage.setItem(OXYGEN_REDIRECT_URL_KEY, redirectUrl ? JSON.stringify(redirectUrl) : JSON.stringify(document.location.href));
  try {
    // Set current URL in local storage to be redirected to after verify of new acct.
    const lrsObject = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_LRS_KEY)) || {};
    lrsObject.redirUrl = redirectUrl || document.location.href;
    window.localStorage.setItem(LOCAL_STORAGE_LRS_KEY, JSON.stringify(lrsObject));
  } catch (e) {}
};

export const LoginClickHandler = (event, redirectUrl) => {
  setLoginRedirects(redirectUrl);
  // Redirect to Login
  DHXOxygen.showSignIn(OXYGEN_RESPONSE_URL);
  // });
};
