function XHRError(statusCode, message, fileName, lineNumber) {
  const instance = new Error(message, fileName, lineNumber);
  instance.name = 'XHRError';
  instance.statusCode = statusCode;
  Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
  if (Error.captureStackTrace) {
    Error.captureStackTrace(instance, XHRError);
  }
  return instance;
}

XHRError.prototype = Object.create(Error.prototype, {
  constructor: {
    value: Error,
    enumerable: false,
    writable: true,
    configurable: true,
  },
});

if (Object.setPrototypeOf) {
  Object.setPrototypeOf(XHRError, Error);
} else {
  // eslint-disable-next-line no-proto
  XHRError.__proto__ = Error;
}

export default XHRError;
